import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState, useRef } from "react";
import {
  csvNonceSwap,
  getNonces,
  getWallets,
  getTokens,
  nonceSwap,
  stopSwapLaunch,
  getTokenInfo,
} from "../../api/api";
import { toast } from "react-toastify";
import Papa from "papaparse";
import { ExpandMore } from "@mui/icons-material";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import useSelectAll from "../../hooks/useSelectAll";

function Nonce({ env, chainId }) {
  const [blockTime, setBlockTime] = useState(12);
  const [primaryWallet, setPrimaryWallet] = useState("");
  const [secondaryWallets, setSecondaryWallets] = useState(null);
  const [secondaryWalletsNonces, setSecondaryWalletsNonces] = useState(null);
  const [period, setPeriod] = useState(0);
  const [maxPeriod, setMaxPeriod] = useState(0);
  const [deviationP, setDeviationP] = useState(0);
  const [tokenAddresses, setTokenAddresses] = useState("");
  const [nonce, setNonce] = useState(-1);
  const [checked, setChecked] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [ethPercent, setEthPercent] = useState(0);
  const [tokenPercent, setTokenPercent] = useState(0);
  const [priorityFee, setPriorityFee] = useState(1);
  const [addOnFee, setAddOnFee] = useState(1);
  const [swapGasLimit, setSwapGasLimit] = useState(100000);
  const [retries, setRetries] = useState(1);
  const [gasPriceLimit, setGasPriceLimit] = useState(0);
  const [blockWait, setBlockWait] = useState(1);
  const [tokens, setTokens] = useState(null);
  const [totalTokens, setTotalTokens] = useState(null);
  const [toApprove, setToApprove] = useState(false);
  const [tokenAddress, setTokenAddress] = useState("");
  const [amount, setAmount] = useState(0);
  const [spenderAddress, setSpenderAddress] = useState("");
  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");

  const [isLoading, setLoading] = useState(false);
  const [speed, setSpeed] = useState(0);
  const [label, setLabel] = useState("Slow");

  const { CopyButton } = useCopyToClipboard();
  const { isAll, selectAll } = useSelectAll();
  const fileInputRef = useRef(null);

  const handleSpeedLabelChange = (e) => {
    setLabel(e.target.value);
  };

  const fetchWallets = async () => {
    const { primaryWallet: pW, secondaryWallets: sWs } = await getWallets(env);
    setPrimaryWallet(pW);
    setSecondaryWallets(sWs);
    setChecked(new Array(sWs.length | 0).fill(false));
  };

  const fetchTokenInfo = async (ca) => {
    if (!ca && ca.trim() === "") return;
    try {
      const { name, symbol } = await getTokenInfo(ca, chainId);
      setName(name);
      setSymbol(symbol);
    } catch (err) {
      toast(err.message, {
        type: "error",
      });
    }
  };

  const fetchNonces = async () => {
    setLoading(true);
    const { secondaryNonces } = await getNonces(chainId, env);
    setSecondaryWalletsNonces(secondaryNonces);
    setLoading(false);
  };

  const fetchTokens = async () => {
    setLoading(true);
    const trueIndexes = checked
      .map((value, index) => (value ? index : -1))
      .filter((index) => index !== -1);
    const selectedAddresses = trueIndexes.map(
      (index) => secondaryWallets[index].address
    );
    const myTokens = await getTokens(selectedAddresses, chainId);
    const length = secondaryWallets?.length;
    let arr = Array.from({ length }, () => []);
    trueIndexes.forEach((index, i) => {
      arr[index] = myTokens[i];
    });
    // Calculate total balances
    const totalBalances = myTokens.flat().reduce((acc, token) => {
      const existing = acc.find((item) => item.ca === token.ca);
      if (existing) {
        existing.totalBalance += token.balance;
      } else {
        acc.push({
          ca: token.ca,
          symbol: token.symbol,
          totalBalance: token.balance,
        });
      }
      return acc;
    }, []);
    setTokens(arr);
    setTotalTokens(totalBalances);
    setLoading(false);
  };

  const getWalletId = (address) => {
    if (address.toLowerCase() === primaryWallet.address.toLowerCase()) {
      // Primary
      return primaryWallet._id;
    } else {
      const index = secondaryWallets.findIndex(
        (wallet) => wallet.address.toLowerCase() === address.toLowerCase()
      );
      return index === -1 ? -1 : secondaryWallets[index]._id;
    }
  };

  const handleFileUpload = (event) => {
    setLoading(false);
    const file = event.target.files[0];

    if (file) {
      Papa.parse(file, {
        header: true, // Parse the header row to extract column names
        skipEmptyLines: true, // Skip empty lines in the CSV file
        complete: function (result) {
          setCsvData(
            result.data
              .map((w) => ({
                ...w,
                id: getWalletId(w.source),
              }))
              .filter((w) => w.id !== -1)
          );
          // Clear the file input after the state has been updated
          fileInputRef.current.value = null;
        },
      });
    }
  };

  const handleChange = async (e) => {
    const { id, checked } = e.target;
    setChecked((prev) => {
      prev[id] = checked;
      return [...prev];
    });
  };

  useEffect(() => {
    fetchWallets();
  }, []);

  useEffect(() => {
    fetchTokenInfo(tokenAddress);
  }, [tokenAddress]);

  useEffect(() => {
    if (label) {
      if (label === "Slow") {
        setSpeed(0);
      } else if (label === "Medium") {
        setSpeed(10);
      } else if (label === "Fast") {
        setSpeed(25);
      }
    }
  }, [label]);

  useEffect(() => {
    let wallets = [];
    if (secondaryWalletsNonces) {
      if (nonce > -1) {
        // Iterate over wallets based on secondaryWalletsNonces arrays
        for (let i = 0; i < secondaryWalletsNonces.length; i++) {
          const nonceValue = parseFloat(secondaryWalletsNonces[i]);

          // Check if wallet qualifies
          let isSeller = nonceValue >= nonce;

          // Add wallet index to wallets list if it's a seller
          if (isSeller) {
            wallets.push(i);
          }
        }
        let walletArr = new Array(secondaryWallets?.length).fill(false);

        wallets.forEach((val) => {
          if (val >= 0 && val < walletArr.length) {
            walletArr[val] = true;
          }
        });

        setChecked(walletArr);
      } else if (nonce == -1) {
        setChecked(new Array(secondaryWallets?.length).fill(false));
      }
    }
  }, [nonce, secondaryWallets, secondaryWalletsNonces]);

  // Group wallets
  const groups = { All: [] };
  secondaryWallets?.forEach((wallet) => {
    if (!wallet.isHidden) {
      // Add to "All" group
      groups.All.push(wallet);

      // Add to tag groups
      wallet.tags.forEach((tag) => {
        if (!groups[tag]) {
          groups[tag] = [];
        }
        groups[tag].push(wallet);
      });
    }
  });

  // Add hidden group
  groups.Hidden = secondaryWallets
    ?.filter((wallet) => wallet.isHidden)
    .map((wallet) => wallet);

  return (
    <>
      <Typography variant="h4" mb={2}>
        Nonce Module
      </Typography>
      <Paper
        sx={{
          p: 2,
          marginBottom: "10px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid container spacing={2} padding={2}>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={async () => fetchNonces()}
              disabled={isLoading}
            >
              Show/Refresh Nonces
            </Button>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={async () => fetchTokens()}
              disabled={isLoading}
              style={{ marginTop: "15px" }}
            >
              Show/Refresh Tokens for selected wallets
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              value={blockWait}
              variant="outlined"
              label="Block Wait (Number of blocks)"
              onChange={(e) => setBlockWait(parseInt(e.target.value))}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <TextField
              value={retries}
              variant="outlined"
              label="Number of retries"
              onChange={(e) => setRetries(parseInt(e.target.value))}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <TextField
              value={swapGasLimit}
              variant="outlined"
              label="Txs gas limit"
              onChange={(e) => setSwapGasLimit(parseInt(e.target.value))}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <TextField
              value={gasPriceLimit}
              variant="outlined"
              label="Gas Price Limit (Gwei)"
              onChange={(e) => setGasPriceLimit(parseFloat(e.target.value))}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <TextField
              value={priorityFee}
              variant="outlined"
              label="Priority Fee (gwei)"
              onChange={(e) => setPriorityFee(parseInt(e.target.value))}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              value={blockTime}
              variant="outlined"
              label="Block Time (12 for Ethereum)"
              onChange={(e) => setBlockTime(parseInt(e.target.value))}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <TextField
              value={period}
              variant="outlined"
              label="Period (seconds)"
              onChange={(e) => setPeriod(parseInt(e.target.value))}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <TextField
              value={maxPeriod}
              variant="outlined"
              label="Max Period (seconds)"
              onChange={(e) => setMaxPeriod(parseInt(e.target.value))}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <TextField
              value={deviationP}
              variant="outlined"
              label="Deviation (%)"
              onChange={(e) => setDeviationP(parseFloat(e.target.value))}
              fullWidth
              style={{ marginBottom: "15px" }}
            />

            <TextField
              value={addOnFee}
              variant="outlined"
              label="Add On Fee (gwei)"
              onChange={(e) => setAddOnFee(parseInt(e.target.value))}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={toApprove}
                  onChange={() => setToApprove((prev) => !prev)}
                />
              }
              label={"Approve new wallets"}
            />
            <br />
            {toApprove && (
              <>
                <TextField
                  value={tokenAddress}
                  variant="outlined"
                  label="Token Contract Address"
                  onChange={(e) => setTokenAddress(e.target.value)}
                  fullWidth
                  style={{ marginBottom: "5px" }}
                  helperText={`Name: ${name}, Symbol: ${symbol}`}
                />
                <TextField
                  value={spenderAddress}
                  variant="outlined"
                  label="Spender Address"
                  onChange={(e) => setSpenderAddress(e.target.value)}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={amount}
                  variant="outlined"
                  label="Amount"
                  onChange={(e) => setAmount(parseFloat(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <FormControl
                  component="fieldset"
                  style={{ marginBottom: "15px" }}
                >
                  <FormLabel component="legend">Select Speed</FormLabel>
                  <RadioGroup
                    aria-label="speed"
                    name="speed"
                    value={label}
                    onChange={handleSpeedLabelChange}
                    row
                  >
                    <FormControlLabel
                      value="Slow"
                      control={<Radio />}
                      label="Slow (current)"
                    />
                    <FormControlLabel
                      value="Medium"
                      control={<Radio />}
                      label="Medium (+10%)"
                    />
                    <FormControlLabel
                      value="Fast"
                      control={<Radio />}
                      label="Fast (+25%)"
                    />
                    <FormControlLabel
                      value="Custom"
                      control={<Radio />}
                      label="Custom"
                    />
                  </RadioGroup>
                  {label === "Custom" && (
                    <TextField
                      label="Custom (%)"
                      type="number"
                      value={speed}
                      onChange={(e) => setSpeed(e.target.value)}
                      margin="normal"
                      fullWidth
                    />
                  )}
                </FormControl>
              </>
            )}
          </Grid>
        </Grid>
      </Paper>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Manual Wallet Swap
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={4} padding={2}>
            <Grid item xs={12} md={6}>
              <TextField
                value={tokenAddresses}
                variant="outlined"
                label="Token Addresses (ca1,ca2)"
                onChange={(e) => setTokenAddresses(e.target.value)}
                fullWidth
                multiline
                InputProps={{
                  style: { resize: "vertical" },
                }}
                style={{ marginBottom: "10px" }}
              />
              <TextField
                value={nonce}
                variant="outlined"
                label="Nonce Filter (-1 for no filter)"
                onChange={(e) => setNonce(parseInt(e.target.value))}
                fullWidth
                style={{ marginBottom: "15px" }}
              />
              <TextField
                value={ethPercent}
                variant="outlined"
                label="ETH Amount (%)"
                onChange={(e) => setEthPercent(parseInt(e.target.value))}
                fullWidth
                style={{ marginBottom: "15px" }}
              />
              <TextField
                value={tokenPercent}
                variant="outlined"
                label="Token Amount (%)"
                onChange={(e) => setTokenPercent(parseInt(e.target.value))}
                fullWidth
                style={{ marginBottom: "15px" }}
              />
              <Button
                variant="contained"
                color="black"
                fullWidth
                onClick={async () => {
                  try {
                    setLoading(true);
                    const trueIndexes = checked
                      .map((value, index) => (value ? index : -1))
                      .filter((index) => index !== -1);
                    const trueIds = trueIndexes.map(
                      (index) => secondaryWallets[index]?._id
                    );

                    await nonceSwap(
                      period,
                      maxPeriod,
                      deviationP,
                      gasPriceLimit,
                      swapGasLimit,
                      blockWait,
                      blockTime,
                      priorityFee,
                      addOnFee,
                      tokenAddresses.split(","),
                      ethPercent,
                      tokenPercent,
                      trueIds,
                      retries,
                      env,
                      toApprove,
                      toApprove ? tokenAddress : "",
                      toApprove ? spenderAddress : "",
                      toApprove ? amount : "",
                      speed,
                      chainId
                    );
                    toast("Nonce Wallet Swap Scheduled", {
                      type: "success",
                    });
                  } catch (e) {
                    toast(e.message, {
                      type: "error",
                    });
                  } finally {
                    setLoading(false);
                  }
                }}
                disabled={isLoading}
              >
                Schedule Swap
              </Button>
              <Button
                variant="contained"
                style={{ marginTop: "10px" }}
                fullWidth
                color="secondary"
                onClick={async () => {
                  try {
                    setLoading(true);

                    await stopSwapLaunch();
                    setLoading(false);
                    toast("Swap stopped", {
                      type: "success",
                    });
                  } catch (e) {
                    setLoading(false);
                    toast(e.message, {
                      type: "error",
                    });
                  }
                }}
                disabled={isLoading}
              >
                Stop Nonce Wallet Swap
              </Button>

              {totalTokens && (
                <div>
                  <h2>Total Tokens</h2>
                  {totalTokens.map((tok) => (
                    <p key={tok.ca}>
                      {tok.totalBalance} {tok.symbol} ({tok.ca})
                    </p>
                  ))}
                </div>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {Object.entries(groups)?.map(([groupName, groupWallets]) => (
                <Accordion key={groupName}>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    {groupName}
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isAll(groupWallets, checked)}
                          onChange={() =>
                            selectAll(groupWallets, checked, setChecked)
                          }
                        />
                      }
                      label={"Select All"}
                      style={{ marginBottom: "5px" }}
                    />
                    {groupWallets?.map((sW) => (
                      <div key={sW?.walletIndex}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              id={sW?.walletIndex}
                              checked={checked[sW?.walletIndex]}
                              onChange={handleChange}
                            />
                          }
                          label={
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "default",
                              }}
                              onClick={(e) => e.preventDefault()}
                            >
                              {sW?.address}
                              <CopyButton text={sW?.address} />
                            </div>
                          }
                        />
                        <FormHelperText>{`Nonce: ${
                          secondaryWalletsNonces
                            ? secondaryWalletsNonces[sW?.walletIndex]
                            : "-"
                        }`}</FormHelperText>
                        {checked[sW?.walletIndex] && (
                          <FormHelperText>
                            {tokens &&
                              tokens[sW?.walletIndex] &&
                              tokens[sW?.walletIndex].map((token) => {
                                return (
                                  <p key={token.ca}>
                                    {token?.balance} {token?.symbol} (
                                    {token?.ca})
                                  </p>
                                );
                              })}
                          </FormHelperText>
                        )}
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          CSV Wallet Swap
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={4} padding={2}>
            <Grid item xs={12}>
              <input
                type="file"
                accept=".csv"
                ref={fileInputRef}
                onChange={async (e) => {
                  try {
                    handleFileUpload(e);
                  } catch (e) {
                    toast(e.message, {
                      type: "error",
                    });
                    setLoading(false);
                  }
                }}
              />
              {csvData.length > 0 && (
                <div>
                  <h3>Data:</h3>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>source</TableCell>
                        <TableCell>dest pk</TableCell>
                        <TableCell>ca</TableCell>
                        <TableCell>eth %</TableCell>
                        <TableCell>token %</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {csvData?.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {row.source.substring(0, 3)}...
                            {row.source.substring(
                              row.source.length - 3,
                              row.source.length
                            )}
                          </TableCell>
                          <TableCell>
                            {row.destpk.substring(0, 3)}...
                            {row.destpk.substring(
                              row.destpk.length - 3,
                              row.destpk.length
                            )}
                          </TableCell>
                          <TableCell>
                            {row.ca.substring(0, 3)}...
                            {row.ca.substring(row.ca.length - 3, row.ca.length)}
                          </TableCell>
                          <TableCell>{row.eth}</TableCell>
                          <TableCell>{row.token}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              )}
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="black"
                fullWidth
                onClick={async () => {
                  try {
                    setLoading(true);

                    await csvNonceSwap(
                      period,
                      maxPeriod,
                      deviationP,
                      gasPriceLimit,
                      swapGasLimit,
                      blockWait,
                      blockTime,
                      priorityFee,
                      addOnFee,
                      csvData,
                      retries,
                      env,
                      toApprove,
                      toApprove ? tokenAddress : "",
                      toApprove ? spenderAddress : "",
                      toApprove ? amount : "",
                      speed,
                      chainId
                    );
                    toast("Nonce CSV Wallet Swap Scheduled", {
                      type: "success",
                    });
                  } catch (e) {
                    toast(e.message, {
                      type: "error",
                    });
                  } finally {
                    setLoading(false);
                  }
                }}
                disabled={isLoading}
              >
                Schedule Swap
              </Button>
              <Button
                variant="contained"
                style={{ marginTop: "10px" }}
                fullWidth
                color="secondary"
                onClick={async () => {
                  try {
                    setLoading(true);

                    await stopSwapLaunch();
                    setLoading(false);
                    toast("Swap stopped", {
                      type: "success",
                    });
                  } catch (e) {
                    setLoading(false);
                    toast(e.message, {
                      type: "error",
                    });
                  }
                }}
                disabled={isLoading}
              >
                Stop Nonce Wallet Swap
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default Nonce;
