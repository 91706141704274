import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Add, ExpandMore } from "@mui/icons-material";
import { useEffect, useState } from "react";
import {
  generateSecondaryWallet,
  getEthBalances,
  getWallets,
  getTokenBalances,
  getTokenInfo,
  scheduleWalletSwap,
  stopSwapLaunch,
  swapAutoLaunch,
  swapLaunch,
  getJobs,
  addTemplate,
} from "../../api/api";
import { toast } from "react-toastify";
import { chains, tokens } from "../constants";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import useSelectAll from "../../hooks/useSelectAll";

function Swap({ env, chainId }) {
  const [blockTime, setBlockTime] = useState(12);
  const [secondaryWallets, setSecondaryWallets] = useState(null);

  const [tokenAddress, setTokenAddress] = useState("");
  const [slippage, setSlippage] = useState(10);
  const [gasPriceLimit, setGasPriceLimit] = useState(0);
  const [period, setPeriod] = useState(600);
  const [blockWait, setBlockWait] = useState(1);
  const [waitPerBundle, setWaitPerBundle] = useState(0);
  const [maxPeriod, setMaxPeriod] = useState(900);
  const [periodDeviation, setPeriodDeviation] = useState(50);
  const [totalAmount, setTotalAmount] = useState(0);
  const [retentionAmount, setRetentionAmount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [maxAmount, setMaxAmount] = useState(0);
  const [amountDeviation, setAmountDeviation] = useState(35);
  const [totalAmountBuy, setTotalAmountBuy] = useState(0);
  const [amountBuy, setAmountBuy] = useState(0);
  const [maxAmountBuy, setMaxAmountBuy] = useState(0);
  const [amountDeviationBuy, setAmountDeviationBuy] = useState(35);
  const [checked, setChecked] = useState([]);
  const [checked2, setChecked2] = useState([]);
  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  // Secondary Wallets
  const [tokenBalances, setTokenBalances] = useState(null);
  const [totalBalance, setTotalBalance] = useState(0);
  const [totalBalanceBuy, setTotalBalanceBuy] = useState(0);

  const [isLoading, setLoading] = useState(false);

  const [speed, setSpeed] = useState(0);
  const [label, setLabel] = useState("Fast");
  const [sellerLabel, setSellerLabel] = useState("Max");
  const [buyerLabel, setBuyerLabel] = useState("Max");
  const [isLimited, setLimited] = useState(false);
  const [limit, setLimit] = useState(-1);

  const [exchangeType, setExchangeType] = useState("ANY");

  const [amount2, setAmount2] = useState(0);
  const [maxAmount2, setMaxAmount2] = useState(0);
  const [deviation2, setDeviation2] = useState(0);
  const [delay2, setDelay2] = useState(0);
  const [sourceChain, setSourceChain] = useState("ethereum");
  const [sourceCurrency, setSourceCurrency] = useState("null");
  const [destinationChain, setDestinationChain] = useState("ethereum");
  const [destinationCurrency, setDestinationCurrency] = useState("null");
  const [sourceBalance, setSourceBalance] = useState(null);
  const [thereNewBuyers, setThereNewBuyers] = useState(false);
  const [numOfNewBuyers, setNumOfNewBuyers] = useState(0);

  const [priorityFee, setPriorityFee] = useState(1);
  const [addOnFee, setAddOnFee] = useState(1);
  const [swapGasLimit, setSwapGasLimit] = useState(175000);
  const [retries, setRetries] = useState(1);
  const [gasLimitIncrement, setGasLimitIncrement] = useState(50);
  const [minNumSellers, setMinNumSellers] = useState(1);
  const [minNumBuyers, setMinNumBuyers] = useState(1);
  const [maxNumSellers, setMaxNumSellers] = useState(2);
  const [maxNumBuyers, setMaxNumBuyers] = useState(5);
  const [numOfBundles, setNumBundles] = useState(0);

  const [isUniswap, setUniswap] = useState(true);
  const [is1inch, set1inch] = useState(false);
  const [isCow, setCow] = useState(false);

  const [ethBalances, setEthBalances] = useState(null);

  const [isNumWalletsSeller, setIsNumWalletsSeller] = useState(true);
  const [isTokenRangeSeller, setIsTokenRangeSeller] = useState(true);
  const [isETHRangeSeller, setIsETHRangeSeller] = useState(true);
  const [numWalletSeller, setNumWalletsSeller] = useState(-1);
  const [minTokenRangeSeller, setMinTokenRangeSeller] = useState(-1);
  const [maxTokenRangeSeller, setMaxTokenRangeSeller] = useState(-1);
  const [minEthRangeSeller, setMinEthRangeSeller] = useState(-1);
  const [maxEthRangeSeller, setMaxEthRangeSeller] = useState(-1);

  const [isNumWalletsBuyer, setIsNumWalletsBuyer] = useState(true);
  const [isTokenRangeBuyer, setIsTokenRangeBuyer] = useState(true);
  const [isETHRangeBuyer, setIsETHRangeBuyer] = useState(true);
  const [enableDuplicates, setEnableDuplicates] = useState(false);
  const [isBundleShuffle, setBundleShuffle] = useState(false);
  const [numWalletBuyer, setNumWalletsBuyer] = useState(-1);
  const [minTokenRangeBuyer, setMinTokenRangeBuyer] = useState(-1);
  const [maxTokenRangeBuyer, setMaxTokenRangeBuyer] = useState(-1);
  const [minEthRangeBuyer, setMinEthRangeBuyer] = useState(-1);
  const [maxEthRangeBuyer, setMaxEthRangeBuyer] = useState(-1);

  const [jobs, setJobs] = useState([]);

  const { CopyButton } = useCopyToClipboard();
  const { isAll, selectAll, isPartOfGroupWallet } = useSelectAll();

  const handleSpeedLabelChange = (e) => {
    setLabel(e.target.value);
  };
  const handleSellerLabelChange = (e) => {
    setSellerLabel(e.target.value);
  };
  const handleBuyerLabelChange = (e) => {
    setBuyerLabel(e.target.value);
  };

  const fetchWallets = async () => {
    const { secondaryWallets: sWs } = await getWallets(env);
    setSecondaryWallets(sWs);
    setChecked(new Array(sWs.length | 0).fill(false));
    setChecked2(new Array(sWs.length | 0).fill(false));
  };

  const fetchJobs = async () => {
    const docs = await getJobs("swapModule", env);
    setJobs(docs);
  };

  const handleWalletSwap = async () => {
    try {
      if (!tokenAddress || tokenAddress.trim() === "") {
        toast("Enter token contract address", {
          type: "error",
        });
      } else {
        setLoading(true);
        const sellerIndexes = checked
          .map((value, index) => (value ? index : -1))
          .filter((index) => index !== -1);
        const buyerIndexes = checked2
          .map((value, index) => (value ? index : -1))
          .filter((index) => index !== -1);
        let newBuyerIndexes = [];

        if (thereNewBuyers) {
          if (numOfNewBuyers < 1) {
            toast("Enter a valid number of new buyers", {
              type: "error",
            });
            setLoading(false);
            return;
          }

          const n = secondaryWallets.length;

          for (let i = 0; i < numOfNewBuyers; i++) {
            newBuyerIndexes.push(n + i);
          }
          await generateSecondaryWallet(numOfNewBuyers, null, env);
        }

        const sellerIds = sellerIndexes.map(
          (index) => secondaryWallets[index]?._id
        );
        const buyerIds = buyerIndexes.map(
          (index) => secondaryWallets[index]?._id
        );
        const newBuyerIds = newBuyerIndexes.map(
          (index) => secondaryWallets[index]?._id
        );

        let platforms = [];
        if (isUniswap) platforms.push("Uniswap");
        if (is1inch) platforms.push("1inch");
        if (isCow) platforms.push("Cow");
        await scheduleWalletSwap(
          tokenAddress,
          slippage / 100,
          gasPriceLimit,
          period,
          maxPeriod,
          periodDeviation,
          amount,
          maxAmount,
          amountDeviation,
          sellerIds,
          buyerIds,
          retentionAmount,
          limit,
          speed,
          newBuyerIds,
          amount2,
          maxAmount2,
          deviation2,
          delay2,
          sourceChain,
          sourceCurrency,
          destinationChain,
          destinationCurrency,
          exchangeType,
          platforms,
          env,
          chainId
        );
        await fetchWallets();
        toast("Token Swap Scheduled", {
          type: "success",
        });
        setLoading(false);
      }
    } catch (e) {
      toast(e.message, {
        type: "error",
      });
      setLoading(false);
    }
  };

  const handleBundleWalletSwap = async () => {
    try {
      if (!tokenAddress || tokenAddress.trim() === "") {
        toast("Enter token contract address", {
          type: "error",
        });
      } else {
        setLoading(true);
        const sellerIndexes = checked
          .map((value, index) => (value ? index : -1))
          .filter((index) => index !== -1);
        const buyerIndexes = checked2
          .map((value, index) => (value ? index : -1))
          .filter((index) => index !== -1);
        let newBuyerIndexes = [];

        if (thereNewBuyers) {
          if (numOfNewBuyers < 1) {
            toast("Enter a valid number of new buyers", {
              type: "error",
            });
            setLoading(false);
            return;
          }

          const n = secondaryWallets.length;

          for (let i = 0; i < numOfNewBuyers; i++) {
            newBuyerIndexes.push(n + i);
          }
          await generateSecondaryWallet(numOfNewBuyers, null, env);
        }

        const sellerIds = sellerIndexes.map(
          (index) => secondaryWallets[index]?._id
        );
        const buyerIds = buyerIndexes.map(
          (index) => secondaryWallets[index]?._id
        );
        const newBuyerIds = newBuyerIndexes.map(
          (index) => secondaryWallets[index]?._id
        );

        await swapLaunch(
          tokenAddress,
          slippage / 100,
          blockWait,
          blockTime,
          period,
          maxPeriod,
          periodDeviation,
          amount,
          maxAmount,
          amountDeviation,
          amountBuy,
          maxAmountBuy,
          amountDeviationBuy,
          sellerIds,
          buyerIds,
          limit,
          speed,
          newBuyerIds,
          amount2,
          maxAmount2,
          deviation2,
          delay2,
          sourceChain,
          sourceCurrency,
          destinationChain,
          destinationCurrency,
          exchangeType,
          priorityFee,
          addOnFee,
          swapGasLimit,
          retries,
          gasLimitIncrement,
          minNumSellers,
          maxNumSellers,
          minNumBuyers,
          maxNumBuyers,
          isBundleShuffle,
          gasPriceLimit,
          env,
          chainId
        );
        await fetchWallets();
        toast("Bundling Token Swap Scheduled", {
          type: "success",
        });
        setLoading(false);
      }
    } catch (e) {
      toast(e.message, {
        type: "error",
      });
      setLoading(false);
    }
  };

  const handleAutoBundleWalletSwap = async () => {
    try {
      if (!tokenAddress || tokenAddress.trim() === "") {
        toast("Enter token contract address", {
          type: "error",
        });
      } else {
        setLoading(true);

        const sellerIndexes = checked
          .map((value, index) => (value ? index : -1))
          .filter((index) => index !== -1);
        const buyerIndexes = checked2
          .map((value, index) => (value ? index : -1))
          .filter((index) => index !== -1);

        const sellerIds = sellerIndexes.map(
          (index) => secondaryWallets[index]?._id
        );
        const buyerIds = buyerIndexes.map(
          (index) => secondaryWallets[index]?._id
        );

        await swapAutoLaunch(
          tokenAddress,
          slippage / 100,
          blockWait,
          blockTime,
          period,
          maxPeriod,
          periodDeviation,
          amount,
          maxAmount,
          amountDeviation,
          amountBuy,
          maxAmountBuy,
          amountDeviationBuy,
          limit,
          speed,
          priorityFee,
          addOnFee,
          swapGasLimit,
          retries,
          gasLimitIncrement,
          minNumSellers,
          maxNumSellers,
          minNumBuyers,
          maxNumBuyers,
          numOfBundles,
          sellerLabel,
          buyerLabel,
          enableDuplicates,
          isTokenRangeSeller,
          isETHRangeSeller,
          isTokenRangeBuyer,
          isETHRangeBuyer,
          minTokenRangeSeller,
          maxTokenRangeSeller,
          minEthRangeSeller,
          maxEthRangeSeller,
          minTokenRangeBuyer,
          maxTokenRangeBuyer,
          minEthRangeBuyer,
          maxEthRangeBuyer,
          sellerIds,
          buyerIds,
          isBundleShuffle,
          gasPriceLimit,
          env,
          chainId
        );
        await fetchWallets();
        toast("Auto Bundling Token Swap Scheduled", {
          type: "success",
        });
        setLoading(false);
      }
    } catch (e) {
      toast(e.message, {
        type: "error",
      });
      setLoading(false);
    }
  };

  const handleChange = async (e) => {
    const { id, checked } = e.target;
    setChecked((prev) => {
      prev[id] = checked;
      return [...prev];
    });
  };

  const handleChange2 = async (e) => {
    const { id, checked } = e.target;
    setChecked2((prev) => {
      prev[id] = checked;
      return [...prev];
    });
  };

  const fetchBalances = async () => {
    if (!tokenAddress || tokenAddress.trim() === "") return;
    setLoading(true);
    {
      const { secondaryBalances } = await getTokenBalances(
        tokenAddress,
        chainId,
        env
      );
      setTokenBalances(secondaryBalances);
    }
    {
      const { secondaryBalances } = await getEthBalances(chainId, env);
      setEthBalances(secondaryBalances);
    }
    if (thereNewBuyers) {
      if (sourceCurrency === "null") {
        const { primaryBalance } = await getEthBalances(sourceChain, env);
        setSourceBalance(primaryBalance);
      } else {
        const { primaryBalance } = await getTokenBalances(
          sourceCurrency,
          sourceChain,
          env
        );
        setSourceBalance(primaryBalance);
      }
    }
    setLoading(false);
  };

  const fetchTokenInfo = async () => {
    try {
      const { name, symbol } = await getTokenInfo(tokenAddress, chainId);
      setName(name);
      setSymbol(symbol);
    } catch (err) {
      toast(err.message, {
        type: "error",
      });
    }
  };

  useEffect(() => {
    fetchWallets();
    fetchJobs();
  }, []);

  useEffect(() => {
    if (tokenAddress && tokenAddress.trim() !== "") {
      fetchTokenInfo();
    }
  }, [tokenAddress]);

  useEffect(() => {
    let totalTime = 0;
    if (period) {
      totalTime += period;
    }
    if (blockTime && blockWait && retries) {
      totalTime += (retries + 1) * (blockWait + 1) * blockTime;
    }
    setWaitPerBundle(totalTime);
  }, [blockWait, retries, period]);

  useEffect(() => {
    if (tokenBalances && tokenAddress) {
      const sellerIndexes = checked
        .map((value, index) => (value ? index : -1))
        .filter((index) => index !== -1);
      let totalB = 0;
      sellerIndexes.forEach((sI) => {
        totalB += tokenBalances[sI] ? parseFloat(tokenBalances[sI]) : 0;
      });
      setTotalBalance(totalB);
    }
  }, [tokenBalances, tokenAddress, checked]);

  useEffect(() => {
    if (tokenBalances && tokenAddress) {
      const buyerIndexes = checked2
        .map((value, index) => (value ? index : -1))
        .filter((index) => index !== -1);
      let totalB = 0;
      buyerIndexes.forEach((sI) => {
        totalB += tokenBalances[sI] ? parseFloat(tokenBalances[sI]) : 0;
      });
      setTotalBalanceBuy(totalB);
    }
  }, [tokenBalances, tokenAddress, checked2]);

  useEffect(() => {
    if (numOfBundles && totalAmount) {
      if (numOfBundles > 0) {
        setAmount(totalAmount / parseInt(numOfBundles));
      }
    }
  }, [numOfBundles, totalAmount]);

  useEffect(() => {
    if (numOfBundles && totalAmountBuy) {
      if (numOfBundles > 0) {
        setAmountBuy(totalAmountBuy / parseInt(numOfBundles));
      }
    }
  }, [numOfBundles, totalAmountBuy]);

  useEffect(() => {
    if (totalBalance) {
      setTotalAmount(totalBalance);
    }
  }, [totalBalance]);

  useEffect(() => {
    if (totalBalanceBuy) {
      setTotalAmountBuy(totalBalanceBuy);
    }
  }, [totalBalanceBuy]);

  useEffect(() => {
    if (label) {
      if (label === "Slow") {
        setSpeed(0);
      } else if (label === "Medium") {
        setSpeed(10);
      } else if (label === "Fast") {
        setSpeed(25);
      }
    }
  }, [label]);

  useEffect(() => {
    if (isLimited) {
      setLimit(0);
    } else {
      setLimit(-1);
    }
  }, [isLimited]);

  useEffect(() => {
    setNumOfNewBuyers(0);
  }, [thereNewBuyers]);

  useEffect(() => {
    if (isNumWalletsSeller) {
      setNumWalletsSeller(100);
    } else {
      setNumWalletsSeller(-1);
    }
  }, [isNumWalletsSeller]);

  useEffect(() => {
    if (isETHRangeSeller) {
      setMinEthRangeSeller(0.1);
      setMaxEthRangeSeller(10);
    } else {
      setMinEthRangeSeller(-1);
      setMaxEthRangeSeller(-1);
    }
  }, [isETHRangeSeller]);

  useEffect(() => {
    if (isTokenRangeSeller) {
      setMinTokenRangeSeller(10000);
      setMaxTokenRangeSeller(150000);
    } else {
      setMinTokenRangeSeller(-1);
      setMaxTokenRangeSeller(-1);
    }
  }, [isTokenRangeSeller]);

  useEffect(() => {
    if (isNumWalletsBuyer) {
      setNumWalletsBuyer(100);
    } else {
      setNumWalletsBuyer(-1);
    }
  }, [isNumWalletsBuyer]);

  useEffect(() => {
    if (isETHRangeBuyer) {
      setMinEthRangeBuyer(0.1);
      setMaxEthRangeBuyer(10);
    } else {
      setMinEthRangeBuyer(-1);
      setMaxEthRangeBuyer(-1);
    }
  }, [isETHRangeBuyer]);

  useEffect(() => {
    if (isTokenRangeBuyer) {
      setMinTokenRangeBuyer(10000);
      setMaxTokenRangeBuyer(150000);
    } else {
      setMinTokenRangeBuyer(-1);
      setMaxTokenRangeBuyer(-1);
    }
  }, [isTokenRangeBuyer]);

  useEffect(() => {
    let buyers = [],
      sellers = [];
    if (ethBalances && tokenBalances) {
      // Iterate over wallets based on ethBalances and tokenBalances arrays
      for (let i = 0; i < ethBalances.length; i++) {
        const ethBalance = parseFloat(ethBalances[i]);
        const tokenBalance = parseFloat(tokenBalances[i]);

        // Check if wallet qualifies as a buyer
        let isBuyer = true;
        if (isETHRangeBuyer) {
          isBuyer =
            ethBalance >= minEthRangeBuyer && ethBalance <= maxEthRangeBuyer;
        }
        if (isTokenRangeBuyer) {
          isBuyer =
            isBuyer &&
            tokenBalance >= minTokenRangeBuyer &&
            tokenBalance <= maxTokenRangeBuyer;
        }

        // Check if wallet qualifies as a seller
        let isSeller = true;
        if (isETHRangeSeller) {
          isSeller =
            ethBalance >= minEthRangeSeller && ethBalance <= maxEthRangeSeller;
        }
        if (isTokenRangeSeller) {
          isSeller =
            isSeller &&
            tokenBalance >= minTokenRangeSeller &&
            tokenBalance <= maxTokenRangeSeller;
        }

        // Add wallet index to buyers list if it's a buyer
        if (isBuyer && buyers.length < numWalletBuyer) {
          buyers.push(i);
        }

        // Add wallet index to sellers list if it's a seller
        if (isSeller && sellers.length < numWalletSeller) {
          sellers.push(i);
        }

        // If duplicates are disabled, remove the wallet from buyers if it's already in sellers
        if (!enableDuplicates && buyers.includes(i) && sellers.includes(i)) {
          const buyerIndex = buyers.indexOf(i);
          buyers.splice(buyerIndex, 1); // Remove from buyers if already in sellers
        }
      }
      // Select the buyers and sellers
      let buyerArr = new Array(secondaryWallets?.length).fill(false);
      let sellerArr = new Array(secondaryWallets?.length).fill(false);

      buyers.forEach((val) => {
        if (val >= 0 && val < buyerArr.length) {
          buyerArr[val] = true;
        }
      });
      sellers.forEach((val) => {
        if (val >= 0 && val < sellerArr.length) {
          sellerArr[val] = true;
        }
      });

      setChecked(sellerArr);
      setChecked2(buyerArr);
    }
  }, [
    numWalletSeller,
    numWalletBuyer,
    minEthRangeBuyer,
    maxEthRangeBuyer,
    minEthRangeSeller,
    maxEthRangeSeller,
    minTokenRangeSeller,
    maxTokenRangeSeller,
    minTokenRangeBuyer,
    maxTokenRangeBuyer,
    enableDuplicates,
    ethBalances,
    tokenBalances,
  ]);

  // Group wallets
  const groups = { All: [] };
  secondaryWallets?.forEach((wallet) => {
    if (!wallet.isHidden) {
      // Add to "All" group
      groups.All.push(wallet);

      // Add to tag groups
      wallet.tags.forEach((tag) => {
        if (!groups[tag]) {
          groups[tag] = [];
        }
        groups[tag].push(wallet);
      });
    }
  });

  // Add hidden group
  groups.Hidden = secondaryWallets
    ?.filter((wallet) => wallet.isHidden)
    .map((wallet) => wallet);

  return (
    <>
      <Typography variant="h4" mb={2}>
        Wallet Swap Module
      </Typography>

      <Paper
        sx={{
          p: 2,
          marginBottom: "10px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid container spacing={2} padding={2}>
          <Grid item xs={12}>
            <FormLabel component="legend">Common Filter</FormLabel>
            <FormControlLabel
              control={
                <Checkbox
                  checked={enableDuplicates}
                  onChange={() => setEnableDuplicates((prev) => !prev)}
                />
              }
              label={"Allow Duplicates"}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isBundleShuffle}
                  onChange={() => setBundleShuffle((prev) => !prev)}
                />
              }
              label={"Randomize Bundle Order"}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormLabel component="legend">Sellers Filter</FormLabel>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isNumWalletsSeller}
                  onChange={() => setIsNumWalletsSeller((prev) => !prev)}
                />
              }
              label={"Number of Wallets"}
            />
            {isNumWalletsSeller && (
              <TextField
                type="number"
                value={numWalletSeller}
                onChange={(e) => setNumWalletsSeller(e.target.value)}
                margin="normal"
                fullWidth
              />
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={isTokenRangeSeller}
                  onChange={() => setIsTokenRangeSeller((prev) => !prev)}
                />
              }
              label={"Token Range"}
            />
            {isTokenRangeSeller && (
              <>
                <TextField
                  value={minTokenRangeSeller}
                  onChange={(e) =>
                    setMinTokenRangeSeller(parseFloat(e.target.value))
                  }
                  margin="normal"
                  fullWidth
                />
                <TextField
                  value={maxTokenRangeSeller}
                  onChange={(e) =>
                    setMaxTokenRangeSeller(parseFloat(e.target.value))
                  }
                  margin="normal"
                  fullWidth
                />
              </>
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={isETHRangeSeller}
                  onChange={() => setIsETHRangeSeller((prev) => !prev)}
                />
              }
              label={"ETH Range"}
            />
            {isETHRangeSeller && (
              <>
                <TextField
                  value={minEthRangeSeller}
                  onChange={(e) =>
                    setMinEthRangeSeller(parseFloat(e.target.value))
                  }
                  margin="normal"
                  fullWidth
                />
                <TextField
                  value={maxEthRangeSeller}
                  onChange={(e) =>
                    setMaxEthRangeSeller(parseFloat(e.target.value))
                  }
                  margin="normal"
                  fullWidth
                />
              </>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <FormLabel component="legend">Buyers Filter</FormLabel>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isNumWalletsBuyer}
                  onChange={() => setIsNumWalletsBuyer((prev) => !prev)}
                />
              }
              label={"Number of Wallets"}
            />
            {isNumWalletsBuyer && (
              <TextField
                type="number"
                value={numWalletBuyer}
                onChange={(e) => setNumWalletsBuyer(e.target.value)}
                margin="normal"
                fullWidth
              />
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={isTokenRangeBuyer}
                  onChange={() => setIsTokenRangeBuyer((prev) => !prev)}
                />
              }
              label={"Token Range"}
            />
            {isTokenRangeBuyer && (
              <>
                <TextField
                  value={minTokenRangeBuyer}
                  onChange={(e) =>
                    setMinTokenRangeBuyer(parseFloat(e.target.value))
                  }
                  margin="normal"
                  fullWidth
                />
                <TextField
                  value={maxTokenRangeBuyer}
                  onChange={(e) =>
                    setMaxTokenRangeBuyer(parseFloat(e.target.value))
                  }
                  margin="normal"
                  fullWidth
                />
              </>
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={isETHRangeBuyer}
                  onChange={() => setIsETHRangeBuyer((prev) => !prev)}
                />
              }
              label={"ETH Range"}
            />
            {isETHRangeBuyer && (
              <>
                <TextField
                  value={minEthRangeBuyer}
                  onChange={(e) =>
                    setMinEthRangeBuyer(parseFloat(e.target.value))
                  }
                  margin="normal"
                  fullWidth
                />
                <TextField
                  value={maxEthRangeBuyer}
                  onChange={(e) =>
                    setMaxEthRangeBuyer(parseFloat(e.target.value))
                  }
                  margin="normal"
                  fullWidth
                />
              </>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <FormLabel component="legend">Common Settings</FormLabel>
            <TextField
              value={gasPriceLimit}
              variant="outlined"
              label="Gas Price Limit (Gwei)"
              onChange={(e) => setGasPriceLimit(parseFloat(e.target.value))}
              fullWidth
              style={{ marginTop: "15px" }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isLimited}
                  onChange={() => setLimited((prev) => !prev)}
                />
              }
              label={"Limit decimals"}
            />
            <br />
            {isLimited && (
              <TextField
                label="Number of decimals"
                type="number"
                value={limit}
                onChange={(e) => setLimit(e.target.value)}
                margin="normal"
              />
            )}
            <FormControl component="fieldset" style={{ marginBottom: "15px" }}>
              <FormLabel component="legend">Select Speed</FormLabel>
              <RadioGroup
                aria-label="speed"
                name="speed"
                value={label}
                onChange={handleSpeedLabelChange}
                row
              >
                <FormControlLabel
                  value="Slow"
                  control={<Radio />}
                  label="Slow (current)"
                />
                <FormControlLabel
                  value="Medium"
                  control={<Radio />}
                  label="Medium (+10%)"
                />
                <FormControlLabel
                  value="Fast"
                  control={<Radio />}
                  label="Fast (+25%)"
                />
                <FormControlLabel
                  value="Custom"
                  control={<Radio />}
                  label="Custom"
                />
              </RadioGroup>
              {label === "Custom" && (
                <TextField
                  label="Custom (%)"
                  type="number"
                  value={speed}
                  onChange={(e) => setSpeed(e.target.value)}
                  margin="normal"
                  fullWidth
                />
              )}
            </FormControl>
          </Grid>
        </Grid>
      </Paper>
      <Paper
        sx={{
          p: 2,
          marginBottom: "10px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            Wallet Swap
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} padding={2}>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={async () => fetchBalances()}
                  disabled={isLoading}
                >
                  Show/Refresh Balances
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={tokenAddress}
                  variant="outlined"
                  label="Token Contract Address"
                  onChange={(e) => setTokenAddress(e.target.value)}
                  fullWidth
                  helperText={`Name: ${name}, Symbol: ${symbol}`}
                  style={{ marginBottom: "5px" }}
                />
                <TextField
                  value={slippage}
                  variant="outlined"
                  label="Slippage (0-100%)"
                  onChange={(e) => {
                    let num = parseFloat(e.target.value);
                    num = num < 0 ? 0 : num % 101;
                    setSlippage(num);
                  }}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={period}
                  variant="outlined"
                  label="Period (seconds)"
                  onChange={(e) => setPeriod(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={maxPeriod}
                  variant="outlined"
                  label="Max Period (seconds)"
                  onChange={(e) => setMaxPeriod(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={periodDeviation}
                  variant="outlined"
                  label="Period Deviation (%)"
                  onChange={(e) =>
                    setPeriodDeviation(parseFloat(e.target.value))
                  }
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={retentionAmount}
                  variant="outlined"
                  label="Retention Token Amount"
                  onChange={(e) =>
                    setRetentionAmount(parseFloat(e.target.value))
                  }
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={totalBalance}
                  variant="outlined"
                  label="Total Amount Available"
                  fullWidth
                  disabled
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={totalAmount}
                  variant="outlined"
                  label="Total Amount"
                  onChange={(e) => setTotalAmount(parseFloat(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={amount}
                  variant="outlined"
                  label="Average Amount"
                  onChange={(e) => {
                    setAmount(parseFloat(e.target.value));
                    const buyerIndexes = checked2
                      .map((value, index) => (value ? index : -1))
                      .filter((index) => index !== -1);
                    const numOfBuyers = buyerIndexes.length;

                    if (numOfBuyers > 0) {
                      setTotalAmount(parseFloat(e.target.value) * numOfBuyers);
                    }
                  }}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={maxAmount}
                  variant="outlined"
                  label="Max Amount"
                  onChange={(e) => setMaxAmount(parseFloat(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={amountDeviation}
                  variant="outlined"
                  label="Amount Deviation (%)"
                  onChange={(e) =>
                    setAmountDeviation(parseFloat(e.target.value))
                  }
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <div>
                  <FormLabel component="legend">Swap Platforms</FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isUniswap}
                        onChange={() => setUniswap((prev) => !prev)}
                      />
                    }
                    label={"Uniswap"}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={is1inch}
                        onChange={() => set1inch((prev) => !prev)}
                      />
                    }
                    label={"1inch"}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isCow}
                        onChange={() => setCow((prev) => !prev)}
                      />
                    }
                    label={"COW"}
                  />
                </div>
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={thereNewBuyers}
                      onChange={() => setThereNewBuyers((prev) => !prev)}
                    />
                  }
                  label={"Generate New Buyers"}
                />
              </Grid>
              {thereNewBuyers && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      value={numOfNewBuyers}
                      variant="outlined"
                      label="Number of secondary wallets to generate"
                      onChange={(e) =>
                        setNumOfNewBuyers(parseInt(e.target.value))
                      }
                      fullWidth
                      style={{ marginBottom: "5px" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth style={{ marginBottom: "15px" }}>
                      <InputLabel>Source Chain</InputLabel>
                      <Select
                        value={sourceChain}
                        label="Source Chain"
                        onChange={(e) => setSourceChain(e.target.value)}
                      >
                        {chains.map((chain) => (
                          <MenuItem
                            key={chain.networkId}
                            value={chain.networkId}
                          >
                            {chain.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth style={{ marginBottom: "10px" }}>
                      <InputLabel>Source Currency</InputLabel>
                      <Select
                        value={sourceCurrency}
                        label="Source Currency"
                        onChange={(e) => setSourceCurrency(e.target.value)}
                      >
                        {tokens[sourceChain].map((token) => (
                          <MenuItem
                            key={token.name}
                            value={token.contractAddress}
                          >
                            {token.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        Balance: {sourceBalance ? sourceBalance : "-"}
                      </FormHelperText>
                    </FormControl>
                    <FormControl fullWidth style={{ marginBottom: "15px" }}>
                      <InputLabel>Destination Chain</InputLabel>
                      <Select
                        value={destinationChain}
                        label="Destination Chain"
                        onChange={(e) => setDestinationChain(e.target.value)}
                      >
                        {chains.map((chain) => (
                          <MenuItem
                            key={chain.networkId}
                            value={chain.networkId}
                          >
                            {chain.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth style={{ marginBottom: "5px" }}>
                      <InputLabel>Destination Currency</InputLabel>
                      <Select
                        value={destinationCurrency}
                        label="Destination Currency"
                        onChange={(e) => setDestinationCurrency(e.target.value)}
                      >
                        {tokens[destinationChain].map((token) => (
                          <MenuItem
                            key={token.name}
                            value={token.contractAddress}
                          >
                            {token.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      fullWidth
                      component="fieldset"
                      style={{ marginBottom: "5px" }}
                    >
                      <FormLabel component="legend">
                        Select Exchange Type
                      </FormLabel>
                      <RadioGroup
                        aria-label="exchangeType"
                        name="exchangeType"
                        value={exchangeType}
                        onChange={(e) => setExchangeType(e.target.value)}
                        row
                      >
                        <FormControlLabel
                          value="ANY"
                          control={<Radio />}
                          label="Both/Any"
                        />
                        <FormControlLabel
                          value="DEX"
                          control={<Radio />}
                          label="DEX"
                        />
                        <FormControlLabel
                          value="CEX"
                          control={<Radio />}
                          label="CEX"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      value={amount2}
                      variant="outlined"
                      label="Avg. amount to fund per wallet"
                      onChange={(e) => setAmount2(parseFloat(e.target.value))}
                      fullWidth
                      style={{ marginBottom: "10px" }}
                      helperText={`Total to be sent: ${
                        amount2 * numOfNewBuyers
                      } - ${maxAmount2 * numOfNewBuyers}`}
                    />
                    <TextField
                      value={deviation2}
                      variant="outlined"
                      label="Deviation (%)"
                      onChange={(e) =>
                        setDeviation2(parseFloat(e.target.value))
                      }
                      fullWidth
                      style={{ marginBottom: "15px" }}
                    />
                    <TextField
                      value={maxAmount2}
                      variant="outlined"
                      label="Max Amount"
                      onChange={(e) =>
                        setMaxAmount2(parseFloat(e.target.value))
                      }
                      fullWidth
                      style={{ marginBottom: "15px" }}
                    />
                    <TextField
                      value={delay2}
                      variant="outlined"
                      label="Delay (seconds)"
                      onChange={(e) => setDelay2(parseInt(e.target.value))}
                      fullWidth
                      style={{ marginBottom: "15px" }}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  style={{ flex: 1 }}
                  fullWidth
                  color="black"
                  onClick={async () => handleWalletSwap()}
                  disabled={isLoading}
                >
                  Schedule Wallet Swap
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            Manual Bundling Wallet Swap
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} padding={2}>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={async () => fetchBalances()}
                  disabled={isLoading}
                >
                  Show/Refresh Balances
                </Button>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={tokenAddress}
                  variant="outlined"
                  label="Token Contract Address"
                  onChange={(e) => setTokenAddress(e.target.value)}
                  fullWidth
                  helperText={`Name: ${name}, Symbol: ${symbol}`}
                  style={{ marginBottom: "5px" }}
                />
                <TextField
                  value={slippage}
                  variant="outlined"
                  label="Slippage (0-100%)"
                  onChange={(e) => {
                    let num = parseFloat(e.target.value);
                    num = num < 0 ? 0 : num % 101;
                    setSlippage(num);
                  }}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={period}
                  variant="outlined"
                  label="Period (seconds)"
                  onChange={(e) => setPeriod(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={maxPeriod}
                  variant="outlined"
                  label="Max Period (seconds)"
                  onChange={(e) => setMaxPeriod(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={periodDeviation}
                  variant="outlined"
                  label="Period Deviation (%)"
                  onChange={(e) =>
                    setPeriodDeviation(parseFloat(e.target.value))
                  }
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={minNumSellers}
                  variant="outlined"
                  label="Min number of sellers in a bundle"
                  onChange={(e) => setMinNumSellers(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={maxNumSellers}
                  variant="outlined"
                  label="Max number of sellers in a bundle"
                  onChange={(e) => setMaxNumSellers(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={totalBalance}
                  variant="outlined"
                  label="Total Amount Available For Selling"
                  fullWidth
                  disabled
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={totalAmount}
                  variant="outlined"
                  label="Total Amount To Sell"
                  onChange={(e) => setTotalAmount(parseFloat(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={amount}
                  variant="outlined"
                  label="Average Amount per Bundle To Sell"
                  onChange={(e) => {
                    setAmount(parseFloat(e.target.value));
                  }}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={maxAmount}
                  variant="outlined"
                  label="Max Amount per Bundle To Sell"
                  onChange={(e) => setMaxAmount(parseFloat(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={amountDeviation}
                  variant="outlined"
                  label="Amount Deviation For Selling (%)"
                  onChange={(e) =>
                    setAmountDeviation(parseFloat(e.target.value))
                  }
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={blockWait}
                  variant="outlined"
                  label="Block Wait (Number of blocks)"
                  onChange={(e) => setBlockWait(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={priorityFee}
                  variant="outlined"
                  label="Priority Fee (gwei)"
                  onChange={(e) => setPriorityFee(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "27px" }}
                />
                <TextField
                  value={addOnFee}
                  variant="outlined"
                  label="Add On Fee (gwei)"
                  onChange={(e) => setAddOnFee(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={swapGasLimit}
                  variant="outlined"
                  label="Txs gas limit"
                  onChange={(e) => setSwapGasLimit(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={retries}
                  variant="outlined"
                  label="Number of retries"
                  onChange={(e) => setRetries(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={gasLimitIncrement}
                  variant="outlined"
                  label="Gas Limit Increment (%)"
                  onChange={(e) =>
                    setGasLimitIncrement(parseFloat(e.target.value))
                  }
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={minNumBuyers}
                  variant="outlined"
                  label="Min number of buyers in a bundle"
                  onChange={(e) => setMinNumBuyers(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={maxNumBuyers}
                  variant="outlined"
                  label="Max number of buyers in a bundle"
                  onChange={(e) => setMaxNumBuyers(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={totalBalanceBuy}
                  variant="outlined"
                  label="Total Amount Available With Buyers"
                  fullWidth
                  disabled
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={totalAmountBuy}
                  variant="outlined"
                  label="Total Amount To Buy"
                  onChange={(e) =>
                    setTotalAmountBuy(parseFloat(e.target.value))
                  }
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={amountBuy}
                  variant="outlined"
                  label="Average Amount per Bundle To Buy"
                  onChange={(e) => {
                    setAmountBuy(parseFloat(e.target.value));
                  }}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={maxAmountBuy}
                  variant="outlined"
                  label="Max Amount per Bundle To Buy"
                  onChange={(e) => setMaxAmountBuy(parseFloat(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={amountDeviationBuy}
                  variant="outlined"
                  label="Amount Deviation For Buying (%)"
                  onChange={(e) =>
                    setAmountDeviationBuy(parseFloat(e.target.value))
                  }
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={blockTime}
                  variant="outlined"
                  label="Block Time (12 for Ethereum)"
                  onChange={(e) => setBlockTime(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "10px" }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={thereNewBuyers}
                      onChange={() => setThereNewBuyers((prev) => !prev)}
                    />
                  }
                  label={"Generate New Buyers"}
                />
              </Grid>
              {thereNewBuyers && (
                <>
                  <Grid item xs={12}>
                    <TextField
                      value={numOfNewBuyers}
                      variant="outlined"
                      label="Number of secondary wallets to generate"
                      onChange={(e) =>
                        setNumOfNewBuyers(parseInt(e.target.value))
                      }
                      fullWidth
                      style={{ marginBottom: "5px" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth style={{ marginBottom: "15px" }}>
                      <InputLabel>Source Chain</InputLabel>
                      <Select
                        value={sourceChain}
                        label="Source Chain"
                        onChange={(e) => setSourceChain(e.target.value)}
                      >
                        {chains.map((chain) => (
                          <MenuItem
                            key={chain.networkId}
                            value={chain.networkId}
                          >
                            {chain.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth style={{ marginBottom: "10px" }}>
                      <InputLabel>Source Currency</InputLabel>
                      <Select
                        value={sourceCurrency}
                        label="Source Currency"
                        onChange={(e) => setSourceCurrency(e.target.value)}
                      >
                        {tokens[sourceChain].map((token) => (
                          <MenuItem
                            key={token.name}
                            value={token.contractAddress}
                          >
                            {token.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        Balance: {sourceBalance ? sourceBalance : "-"}
                      </FormHelperText>
                    </FormControl>
                    <FormControl fullWidth style={{ marginBottom: "15px" }}>
                      <InputLabel>Destination Chain</InputLabel>
                      <Select
                        value={destinationChain}
                        label="Destination Chain"
                        onChange={(e) => setDestinationChain(e.target.value)}
                      >
                        {chains.map((chain) => (
                          <MenuItem
                            key={chain.networkId}
                            value={chain.networkId}
                          >
                            {chain.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth style={{ marginBottom: "5px" }}>
                      <InputLabel>Destination Currency</InputLabel>
                      <Select
                        value={destinationCurrency}
                        label="Destination Currency"
                        onChange={(e) => setDestinationCurrency(e.target.value)}
                      >
                        {tokens[destinationChain].map((token) => (
                          <MenuItem
                            key={token.name}
                            value={token.contractAddress}
                          >
                            {token.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl
                      fullWidth
                      component="fieldset"
                      style={{ marginBottom: "5px" }}
                    >
                      <FormLabel component="legend">
                        Select Exchange Type
                      </FormLabel>
                      <RadioGroup
                        aria-label="exchangeType"
                        name="exchangeType"
                        value={exchangeType}
                        onChange={(e) => setExchangeType(e.target.value)}
                        row
                      >
                        <FormControlLabel
                          value="ANY"
                          control={<Radio />}
                          label="Both/Any"
                        />
                        <FormControlLabel
                          value="DEX"
                          control={<Radio />}
                          label="DEX"
                        />
                        <FormControlLabel
                          value="CEX"
                          control={<Radio />}
                          label="CEX"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      value={amount2}
                      variant="outlined"
                      label="Avg. amount to fund per wallet"
                      onChange={(e) => setAmount2(parseFloat(e.target.value))}
                      fullWidth
                      style={{ marginBottom: "10px" }}
                      helperText={`Total to be sent: ${
                        amount2 * numOfNewBuyers
                      } - ${maxAmount2 * numOfNewBuyers}`}
                    />
                    <TextField
                      value={deviation2}
                      variant="outlined"
                      label="Deviation (%)"
                      onChange={(e) =>
                        setDeviation2(parseFloat(e.target.value))
                      }
                      fullWidth
                      style={{ marginBottom: "15px" }}
                    />
                    <TextField
                      value={maxAmount2}
                      variant="outlined"
                      label="Max Amount"
                      onChange={(e) =>
                        setMaxAmount2(parseFloat(e.target.value))
                      }
                      fullWidth
                      style={{ marginBottom: "15px" }}
                    />
                    <TextField
                      value={delay2}
                      variant="outlined"
                      label="Delay (seconds)"
                      onChange={(e) => setDelay2(parseInt(e.target.value))}
                      fullWidth
                      style={{ marginBottom: "15px" }}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <Typography variant="h6" component="p" color="primary">
                  Wait time per bundle: ~{waitPerBundle}s (~
                  {parseInt(waitPerBundle / 60)} minutes)
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  style={{ flex: 1, marginBottom: "15px" }}
                  fullWidth
                  color="black"
                  onClick={async () => handleBundleWalletSwap()}
                  disabled={isLoading}
                >
                  Schedule Bundling Wallet Swap
                </Button>
                <Button
                  variant="contained"
                  style={{ flex: 1 }}
                  fullWidth
                  color="secondary"
                  onClick={async () => {
                    try {
                      setLoading(true);

                      await stopSwapLaunch();
                      setLoading(false);
                      toast("Bundling Token Swap stopped", {
                        type: "success",
                      });
                    } catch (e) {
                      setLoading(false);
                      toast(e.message, {
                        type: "error",
                      });
                    }
                  }}
                  disabled={isLoading}
                >
                  Stop Bundling Wallet Swap
                </Button>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            Auto Bundling Wallet Swap
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2} padding={2}>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={async () => fetchBalances()}
                  disabled={isLoading}
                >
                  Show/Refresh Balances
                </Button>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    Choose a Template
                  </AccordionSummary>
                  <AccordionDetails>
                    {jobs
                      ?.filter((job) => {
                        return job.type === "Auto Bundling Swap" && job.label;
                      })
                      .map((job) => (
                        <div
                          key={job?._id}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "default",
                          }}
                          onClick={(e) => e.preventDefault()}
                        >
                          {job?.label}
                          <IconButton
                            aria-label="jobs"
                            onClick={async () => {
                              try {
                                setTokenAddress(job?.tokenAddress);
                                const isSellToken = job?.isTokenRangeSeller;
                                const isBuyToken = job?.isTokenRangeBuyer;
                                const isSellETH = job?.isETHRangeSeller;
                                const isBuyETH = job?.isETHRangeBuyer;
                                setIsTokenRangeSeller(isSellToken);
                                setIsTokenRangeBuyer(isBuyToken);
                                setIsETHRangeSeller(isSellETH);
                                setIsETHRangeBuyer(isBuyETH);
                                setSlippage(
                                  parseFloat(job?.slippage || 0) * 100
                                );
                                setPeriod(job?.period);
                                setMaxPeriod(job?.maxPeriod);
                                setPeriodDeviation(job?.periodDeviation);
                                setAmount(job?.amount);
                                setMaxAmount(job?.maxAmount);
                                setAmountDeviation(job?.amountDeviation);
                                setGasPriceLimit(job?.gasPriceLimit);
                                setBlockWait(job?.blockWait);
                                setAmountBuy(job?.amountBuy);
                                setMaxAmountBuy(job?.maxAmountBuy);
                                setAmountDeviationBuy(job?.amountDeviationBuy);
                                setPriorityFee(job?.priorityFee);
                                setAddOnFee(job?.addOnFee);
                                setSwapGasLimit(job?.swapGasLimit);
                                setRetries(job?.retries);
                                setGasLimitIncrement(job?.gasLimitIncrement);
                                setMinNumSellers(job?.minNumSellers);
                                setMaxNumSellers(job?.maxNumSellers);
                                setMinNumBuyers(job?.minNumBuyers);
                                setMaxNumBuyers(job?.maxNumBuyers);
                                setBundleShuffle(job?.isBundleShuffle);
                                setNumBundles(job?.numOfBundles);
                                setSellerLabel(job?.sellerCriteria);
                                setBuyerLabel(job?.buyerCriteria);
                                setEnableDuplicates(job?.enableDuplicates);

                                if (isSellToken) {
                                  setMinTokenRangeSeller(
                                    job?.minTokenRangeSeller
                                  );
                                  setMaxTokenRangeSeller(
                                    job?.maxTokenRangeSeller
                                  );
                                }
                                if (isBuyToken) {
                                  setMinTokenRangeBuyer(
                                    job?.minTokenRangeBuyer
                                  );
                                  setMaxTokenRangeBuyer(
                                    job?.maxTokenRangeBuyer
                                  );
                                }
                                if (isSellETH) {
                                  setMinEthRangeSeller(job?.minEthRangeSeller);
                                  setMaxEthRangeSeller(job?.maxEthRangeSeller);
                                }
                                if (isBuyETH) {
                                  setMinEthRangeBuyer(job?.minEthRangeBuyer);
                                  setMaxEthRangeBuyer(job?.maxEthRangeBuyer);
                                }
                                toast("Fields Populated", {
                                  type: "success",
                                });
                              } catch (e) {
                                toast(e.message, {
                                  type: "error",
                                });
                              }
                            }}
                          >
                            <Add />
                          </IconButton>
                        </div>
                      ))}
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={tokenAddress}
                  variant="outlined"
                  label="Token Contract Address"
                  onChange={(e) => setTokenAddress(e.target.value)}
                  fullWidth
                  helperText={`Name: ${name}, Symbol: ${symbol}`}
                  style={{ marginBottom: "5px" }}
                />
                <TextField
                  value={slippage}
                  variant="outlined"
                  label="Slippage (0-100%)"
                  onChange={(e) => {
                    let num = parseFloat(e.target.value);
                    num = num < 0 ? 0 : num % 101;
                    setSlippage(num);
                  }}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={period}
                  variant="outlined"
                  label="Period (seconds)"
                  onChange={(e) => setPeriod(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={maxPeriod}
                  variant="outlined"
                  label="Max Period (seconds)"
                  onChange={(e) => setMaxPeriod(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={periodDeviation}
                  variant="outlined"
                  label="Period Deviation (%)"
                  onChange={(e) =>
                    setPeriodDeviation(parseFloat(e.target.value))
                  }
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={minNumSellers}
                  variant="outlined"
                  label="Min number of sellers in a bundle"
                  onChange={(e) => setMinNumSellers(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={maxNumSellers}
                  variant="outlined"
                  label="Max number of sellers in a bundle"
                  onChange={(e) => setMaxNumSellers(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={totalAmount}
                  variant="outlined"
                  label="Total Amount To Sell"
                  onChange={(e) => setTotalAmount(parseFloat(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={amount}
                  variant="outlined"
                  label="Average Amount per Bundle To Sell"
                  onChange={(e) => {
                    setAmount(parseFloat(e.target.value));

                    setTotalAmount(
                      parseFloat(e.target.value) * parseInt(numOfBundles)
                    );
                  }}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={maxAmount}
                  variant="outlined"
                  label="Max Amount per Bundle To Sell"
                  onChange={(e) => setMaxAmount(parseFloat(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={amountDeviation}
                  variant="outlined"
                  label="Amount Deviation For Selling (%)"
                  onChange={(e) =>
                    setAmountDeviation(parseFloat(e.target.value))
                  }
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={blockWait}
                  variant="outlined"
                  label="Block Wait (Number of blocks)"
                  onChange={(e) => setBlockWait(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={blockTime}
                  variant="outlined"
                  label="Block Time (12 for Ethereum)"
                  onChange={(e) => setBlockTime(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  value={priorityFee}
                  variant="outlined"
                  label="Priority Fee (gwei)"
                  onChange={(e) => setPriorityFee(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "27px" }}
                />
                <TextField
                  value={addOnFee}
                  variant="outlined"
                  label="Add On Fee (gwei)"
                  onChange={(e) => setAddOnFee(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={swapGasLimit}
                  variant="outlined"
                  label="Txs gas limit"
                  onChange={(e) => setSwapGasLimit(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={retries}
                  variant="outlined"
                  label="Number of retries"
                  onChange={(e) => setRetries(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={gasLimitIncrement}
                  variant="outlined"
                  label="Gas Limit Increment (%)"
                  onChange={(e) =>
                    setGasLimitIncrement(parseFloat(e.target.value))
                  }
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={minNumBuyers}
                  variant="outlined"
                  label="Min number of buyers in a bundle"
                  onChange={(e) => setMinNumBuyers(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={maxNumBuyers}
                  variant="outlined"
                  label="Max number of buyers in a bundle"
                  onChange={(e) => setMaxNumBuyers(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={totalAmountBuy}
                  variant="outlined"
                  label="Total Amount To Buy"
                  onChange={(e) =>
                    setTotalAmountBuy(parseFloat(e.target.value))
                  }
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={amountBuy}
                  variant="outlined"
                  label="Average Amount per Bundle To Buy"
                  onChange={(e) => {
                    setAmountBuy(parseFloat(e.target.value));
                    setTotalAmountBuy(
                      parseFloat(e.target.value) * parseInt(numOfBundles)
                    );
                  }}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={maxAmountBuy}
                  variant="outlined"
                  label="Max Amount per Bundle To Buy"
                  onChange={(e) => setMaxAmountBuy(parseFloat(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={amountDeviationBuy}
                  variant="outlined"
                  label="Amount Deviation For Buying (%)"
                  onChange={(e) =>
                    setAmountDeviationBuy(parseFloat(e.target.value))
                  }
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={numOfBundles}
                  variant="outlined"
                  label="Number of Bundles"
                  onChange={(e) => setNumBundles(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl
                  component="fieldset"
                  style={{ marginBottom: "5px" }}
                >
                  <FormLabel component="legend">Seller Criteria</FormLabel>
                  <RadioGroup
                    aria-label="seller"
                    name="seller"
                    value={sellerLabel}
                    onChange={handleSellerLabelChange}
                    row
                  >
                    <FormControlLabel
                      value="Min"
                      control={<Radio />}
                      label="Min Tokens"
                    />
                    <FormControlLabel
                      value="Max"
                      control={<Radio />}
                      label="Max Tokens"
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl
                  component="fieldset"
                  style={{ marginBottom: "5px" }}
                >
                  <FormLabel component="legend">Buyer Criteria</FormLabel>
                  <RadioGroup
                    aria-label="buyer"
                    name="buyer"
                    value={buyerLabel}
                    onChange={handleBuyerLabelChange}
                    row
                  >
                    <FormControlLabel
                      value="Min"
                      control={<Radio />}
                      label="Min ETH"
                    />
                    <FormControlLabel
                      value="Max"
                      control={<Radio />}
                      label="Max ETH"
                    />
                  </RadioGroup>
                </FormControl>
                <Typography variant="h6" component="p" color="primary">
                  Wait time per bundle: ~{waitPerBundle}s (~
                  {parseInt(waitPerBundle / 60)} minutes)
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  style={{ flex: 1, marginBottom: "15px" }}
                  fullWidth
                  color="black"
                  onClick={async () => handleAutoBundleWalletSwap()}
                  disabled={isLoading}
                >
                  Schedule Auto Bundling Wallet Swap
                </Button>
                <Button
                  variant="contained"
                  style={{ flex: 1 }}
                  fullWidth
                  color="secondary"
                  onClick={async () => {
                    try {
                      setLoading(true);

                      await stopSwapLaunch();
                      setLoading(false);
                      toast("Auto Bundling Token Swap stopped", {
                        type: "success",
                      });
                    } catch (e) {
                      setLoading(false);
                      toast(e.message, {
                        type: "error",
                      });
                    }
                  }}
                  disabled={isLoading}
                >
                  Stop Auto Bundling Wallet Swap
                </Button>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    Add Jobs to Templates
                  </AccordionSummary>
                  <AccordionDetails>
                    {jobs
                      ?.filter((job) => {
                        return job.type === "Auto Bundling Swap" && !job.label;
                      })
                      .map((job) => (
                        <div
                          key={job?._id}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "default",
                          }}
                          onClick={(e) => e.preventDefault()}
                        >
                          {job?._id}
                          <IconButton
                            aria-label="jobs"
                            onClick={async () => {
                              try {
                                const userInput = window.prompt("Give a label");
                                await addTemplate(
                                  job?._id,
                                  "swapModule",
                                  userInput
                                );
                                await fetchJobs();
                                toast("Label Added", {
                                  type: "success",
                                });
                              } catch (e) {
                                toast(e.message, {
                                  type: "error",
                                });
                              }
                            }}
                          >
                            <Add />
                          </IconButton>
                        </div>
                      ))}
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        <Grid container spacing={2} padding={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h5">Select Sellers</Typography>
            <FormGroup>
              {Object.entries(groups)?.map(([groupName, groupWallets]) => (
                <Accordion key={groupName}>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    {groupName}
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isAll(groupWallets, checked)}
                          onChange={() =>
                            selectAll(groupWallets, checked, setChecked)
                          }
                        />
                      }
                      label={`Select All (${
                        checked
                          ?.map((value, index) => (value ? index : -1))
                          ?.filter((index) => index !== -1)
                          ?.filter((index) =>
                            isPartOfGroupWallet(index, groupWallets)
                          ).length
                      } selected)`}
                      style={{ marginBottom: "5px" }}
                    />
                    {groupWallets?.map((sW) => (
                      <div key={sW?.walletIndex}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              id={sW?.walletIndex}
                              checked={checked[sW?.walletIndex]}
                              onChange={handleChange}
                            />
                          }
                          label={
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "default",
                              }}
                              onClick={(e) => e.preventDefault()}
                            >
                              {sW?.address}
                              <CopyButton text={sW?.address} />
                            </div>
                          }
                        />
                        <FormHelperText>{`Balance: ${
                          tokenBalances ? tokenBalances[sW?.walletIndex] : "-"
                        } ${symbol}`}</FormHelperText>
                        <FormHelperText>{`ETH Balance: ${
                          ethBalances ? ethBalances[sW?.walletIndex] : "-"
                        }`}</FormHelperText>
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))}
            </FormGroup>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h5">Select Buyers</Typography>
            <FormGroup>
              {Object.entries(groups)?.map(([groupName, groupWallets]) => (
                <Accordion key={groupName}>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    {groupName}
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isAll(groupWallets, checked2)}
                          onChange={() =>
                            selectAll(groupWallets, checked2, setChecked2)
                          }
                        />
                      }
                      label={`Select All (${
                        checked2
                          ?.map((value, index) => (value ? index : -1))
                          ?.filter((index) => index !== -1)
                          ?.filter((index) =>
                            isPartOfGroupWallet(index, groupWallets)
                          ).length
                      } selected)`}
                      style={{ marginBottom: "5px" }}
                    />
                    {groupWallets?.map((sW) => (
                      <div key={sW?.walletIndex}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              id={sW?.walletIndex}
                              checked={checked2[sW?.walletIndex]}
                              onChange={handleChange2}
                            />
                          }
                          label={
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "default",
                              }}
                              onClick={(e) => e.preventDefault()}
                            >
                              {sW?.address}
                              <CopyButton text={sW?.address} />
                            </div>
                          }
                        />
                        <FormHelperText>{`Balance: ${
                          tokenBalances ? tokenBalances[sW?.walletIndex] : "-"
                        } ${symbol}`}</FormHelperText>
                        <FormHelperText>{`ETH Balance: ${
                          ethBalances ? ethBalances[sW?.walletIndex] : "-"
                        }`}</FormHelperText>
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))}
            </FormGroup>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default Swap;
