import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  getEthBalances,
  getWallets,
  getTokenBalances,
  getTokenInfo,
  collectFunds,
  sendToken,
} from "../../api/api";
import { toast } from "react-toastify";
import { chains, tokens } from "../constants";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import { ExpandMore } from "@mui/icons-material";
import useSelectAll from "../../hooks/useSelectAll";

function Batch({ env, chainId }) {
  const [secondaryWallets, setSecondaryWallets] = useState(null);
  const [amount, setAmount] = useState(0);
  const [recipient, setRecipient] = useState("");
  const [tokenAddress, setTokenAddress] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [checked, setChecked] = useState([]);
  // Secondary Wallets
  const [tokenBalances, setTokenBalances] = useState(null);

  const [speed, setSpeed] = useState(0);
  const [label, setLabel] = useState("Slow");
  const [isLimited, setLimited] = useState(false);
  const [limit, setLimit] = useState(-1);

  const [exchangeType, setExchangeType] = useState("ANY");

  const [sourceChain, setSourceChain] = useState("ethereum");
  const [sourceCurrency, setSourceCurrency] = useState("null");
  const [destinationChain, setDestinationChain] = useState("ethereum");
  const [destinationCurrency, setDestinationCurrency] = useState("null");

  const { CopyButton } = useCopyToClipboard();
  const { isAll, selectAll } = useSelectAll();

  const handleSpeedLabelChange = (e) => {
    setLabel(e.target.value);
  };

  const fetchWallets = async () => {
    const { secondaryWallets: sWs } = await getWallets(env);
    setSecondaryWallets(sWs);
    setChecked(new Array(sWs.length | 0).fill(false));
  };

  const fetchBalances = async () => {
    if (!tokenAddress || tokenAddress.trim() === "") {
      setLoading(true);
      if (sourceCurrency === "null") {
        const { secondaryBalances } = await getEthBalances(sourceChain, env);
        setTokenBalances(secondaryBalances);
      } else {
        const { primaryBalance } = await getTokenBalances(
          sourceCurrency,
          sourceChain,
          env
        );
        setTokenBalances(primaryBalance);
      }
      setLoading(false);
    } else {
      setLoading(true);
      const { secondaryBalances } = await getTokenBalances(
        tokenAddress,
        chainId,
        env
      );
      setTokenBalances(secondaryBalances);
      setLoading(false);
    }
  };

  const handleSend = async () => {
    try {
      if (!amount || amount < 0 || amount > 100) {
        toast("Enter a valid amount value", {
          type: "error",
        });
      } else {
        const trueIndexes = checked
          .map((value, index) => (value ? index : -1))
          .filter((index) => index !== -1);
        const trueIds = trueIndexes.map(
          (index) => secondaryWallets[index]?._id
        );
        if (!tokenAddress || tokenAddress.trim() === "") {
          setLoading(true);
          await collectFunds(
            recipient,
            amount,
            trueIds,
            limit,
            speed,
            sourceChain,
            sourceCurrency === "null" ? null : sourceCurrency,
            destinationChain,
            destinationCurrency === "null" ? null : destinationCurrency,
            exchangeType,
            env,
            chainId
          );
          await fetchWallets();
          toast("Bridging Scheduled", {
            type: "success",
          });
          setLoading(false);
        } else {
          setLoading(true);
          await sendToken(
            recipient,
            amount,
            trueIds,
            tokenAddress,
            limit,
            speed,
            env,
            chainId
          );
          await fetchWallets();
          toast("Token Send scheduled", {
            type: "success",
          });
          setLoading(false);
        }
      }
    } catch (e) {
      toast(e.message, {
        type: "error",
      });
      setLoading(false);
    }
  };

  const fetchTokenInfo = async () => {
    if (tokenAddress && tokenAddress.trim() !== "") {
      try {
        const { name, symbol } = await getTokenInfo(tokenAddress, chainId);
        setName(name);
        setSymbol(symbol);
      } catch (err) {
        toast(err.message, {
          type: "error",
        });
      }
    } else {
      setName("");
      setSymbol("");
    }
  };

  const handleChange = async (e) => {
    const { id, checked } = e.target;
    setChecked((prev) => {
      prev[id] = checked;
      return [...prev];
    });
  };

  useEffect(() => {
    fetchTokenInfo();
  }, [tokenAddress]);

  useEffect(() => {
    fetchWallets();
  }, []);

  useEffect(() => {
    if (label) {
      if (label === "Slow") {
        setSpeed(0);
      } else if (label === "Medium") {
        setSpeed(10);
      } else if (label === "Fast") {
        setSpeed(25);
      }
    }
  }, [label]);

  useEffect(() => {
    if (isLimited) {
      setLimit(0);
    } else {
      setLimit(-1);
    }
  }, [isLimited]);

  // Group wallets
  const groups = { All: [] };
  secondaryWallets?.forEach((wallet) => {
    if (!wallet.isHidden) {
      // Add to "All" group
      groups.All.push(wallet);

      // Add to tag groups
      wallet.tags.forEach((tag) => {
        if (!groups[tag]) {
          groups[tag] = [];
        }
        groups[tag].push(wallet);
      });
    }
  });

  // Add hidden group
  groups.Hidden = secondaryWallets
    ?.filter((wallet) => wallet.isHidden)
    .map((wallet) => wallet);

  return (
    <>
      <Typography variant="h4" mb={2}>
        Batch Collection Module
      </Typography>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid container spacing={4} padding={2}>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={async () => fetchBalances()}
              disabled={isLoading}
            >
              Show/Refresh Balances
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            {Object.entries(groups)?.map(([groupName, groupWallets]) => (
              <Accordion key={groupName}>
                <AccordionSummary expandIcon={<ExpandMore />}>
                  {groupName}
                </AccordionSummary>
                <AccordionDetails>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isAll(groupWallets, checked)}
                        onChange={() =>
                          selectAll(groupWallets, checked, setChecked)
                        }
                      />
                    }
                    label={"Select All"}
                    style={{ marginBottom: "5px" }}
                  />
                  {groupWallets?.map((sW) => (
                    <div key={sW?.walletIndex}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id={sW?.walletIndex}
                            checked={checked[sW?.walletIndex]}
                            onChange={handleChange}
                          />
                        }
                        label={
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "default",
                            }}
                            onClick={(e) => e.preventDefault()}
                          >
                            {sW?.address}
                            <CopyButton text={sW?.address} />
                          </div>
                        }
                      />
                      <FormHelperText>{`Balance: ${
                        tokenBalances ? tokenBalances[sW?.walletIndex] : "-"
                      }`}</FormHelperText>
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              value={amount}
              variant="outlined"
              label="Amount to send (%)"
              onChange={(e) => setAmount(parseFloat(e.target.value))}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <TextField
              value={recipient}
              variant="outlined"
              label="Recipient Address"
              onChange={(e) => setRecipient(e.target.value)}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <FormControl fullWidth style={{ marginBottom: "15px" }}>
              <InputLabel>Source Chain</InputLabel>
              <Select
                value={sourceChain}
                label="Source Chain"
                onChange={(e) => {
                  setSourceChain(e.target.value);
                  setTokenAddress("");
                }}
              >
                {chains.map((chain) => (
                  <MenuItem key={chain.networkId} value={chain.networkId}>
                    {chain.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth style={{ marginBottom: "15px" }}>
              <InputLabel>Source Currency</InputLabel>
              <Select
                value={sourceCurrency}
                label="Source Currency"
                onChange={(e) => {
                  setSourceCurrency(e.target.value);
                  setTokenAddress("");
                }}
              >
                {tokens[sourceChain].map((token) => (
                  <MenuItem key={token.name} value={token.contractAddress}>
                    {token.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth style={{ marginBottom: "15px" }}>
              <InputLabel>Destination Chain</InputLabel>
              <Select
                value={destinationChain}
                label="Destination Chain"
                onChange={(e) => {
                  setDestinationChain(e.target.value);
                  setTokenAddress("");
                }}
              >
                {chains.map((chain) => (
                  <MenuItem key={chain.networkId} value={chain.networkId}>
                    {chain.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth style={{ marginBottom: "15px" }}>
              <InputLabel>Destination Currency</InputLabel>
              <Select
                value={destinationCurrency}
                label="Destination Currency"
                onChange={(e) => {
                  setDestinationCurrency(e.target.value);
                  setTokenAddress("");
                }}
              >
                {tokens[destinationChain].map((token) => (
                  <MenuItem key={token.name} value={token.contractAddress}>
                    {token.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              fullWidth
              component="fieldset"
              style={{ marginBottom: "5px" }}
            >
              <FormLabel component="legend">Select Exchange Type</FormLabel>
              <RadioGroup
                aria-label="exchangeType"
                name="exchangeType"
                value={exchangeType}
                onChange={(e) => setExchangeType(e.target.value)}
                row
              >
                <FormControlLabel
                  value="ANY"
                  control={<Radio />}
                  label="Both/Any"
                />
                <FormControlLabel value="DEX" control={<Radio />} label="DEX" />
                <FormControlLabel value="CEX" control={<Radio />} label="CEX" />
              </RadioGroup>
            </FormControl>
            <Typography
              component="h4"
              variant="h5"
              style={{ marginBottom: "10px" }}
              color="secondary"
            >
              ----- OR -----
            </Typography>
            <TextField
              value={tokenAddress}
              variant="outlined"
              label="Token Address (For direct token send on the active chain)"
              onChange={(e) => setTokenAddress(e.target.value)}
              fullWidth
              style={{ marginBottom: "5px" }}
              helperText={`Name: ${name}, Symbol: ${symbol}`}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isLimited}
                  onChange={() => setLimited((prev) => !prev)}
                />
              }
              label={"Limit decimals"}
            />
            <br />
            {isLimited && (
              <TextField
                label="Number of decimals"
                type="number"
                value={limit}
                onChange={(e) => setLimit(e.target.value)}
                margin="normal"
              />
            )}
            <FormControl component="fieldset" style={{ marginBottom: "15px" }}>
              <FormLabel component="legend">Select Speed</FormLabel>
              <RadioGroup
                aria-label="speed"
                name="speed"
                value={label}
                onChange={handleSpeedLabelChange}
                row
              >
                <FormControlLabel
                  value="Slow"
                  control={<Radio />}
                  label="Slow (current)"
                />
                <FormControlLabel
                  value="Medium"
                  control={<Radio />}
                  label="Medium (+10%)"
                />
                <FormControlLabel
                  value="Fast"
                  control={<Radio />}
                  label="Fast (+25%)"
                />
                <FormControlLabel
                  value="Custom"
                  control={<Radio />}
                  label="Custom"
                />
              </RadioGroup>
              {label === "Custom" && (
                <TextField
                  label="Custom (%)"
                  type="number"
                  value={speed}
                  onChange={(e) => setSpeed(e.target.value)}
                  margin="normal"
                  fullWidth
                />
              )}
            </FormControl>
            <Button
              variant="contained"
              color="black"
              fullWidth
              onClick={async () => handleSend()}
              disabled={isLoading}
            >
              Send from selected wallets
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default Batch;
