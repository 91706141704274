import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  approvalSchedule,
  getWallets,
  getTokenBalances,
  getTokenInfo,
  marketLiquidate,
  sellAllToken,
  timeLiquidate,
  getJobs,
  addTemplate,
} from "../../api/api";
import { toast } from "react-toastify";
import { Add, ExpandMore } from "@mui/icons-material";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import useSelectAll from "../../hooks/useSelectAll";

function Liquidation({ env, chainId }) {
  const [secondaryWallets, setSecondaryWallets] = useState(null);

  const [tokenAddress, setTokenAddress] = useState("");
  const [slippage, setSlippage] = useState(0);
  const [period, setPeriod] = useState(0);
  const [maxPeriod, setMaxPeriod] = useState(0);
  const [deviation, setDeviation] = useState(0);
  const [checked, setChecked] = useState([]);
  const [amount, setAmount] = useState(0);
  const [maxAmount, setMaxAmount] = useState(0);
  const [amountDeviation, setAmountDeviation] = useState(0);
  const [spenderAddress, setSpenderAddress] = useState("");

  const [pairAddress, setPairAddress] = useState("");
  const [threshold, setThreshold] = useState(0);
  const [percentAmount, setPercentAmount] = useState(0);
  const [prevBlockNum, setPrevBlockNum] = useState(1);
  const [numOfLiquidations, setNumLiquidations] = useState(0);

  const [isLoading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  // Secondary Wallets
  const [tokenBalances, setTokenBalances] = useState(null);

  const [speed, setSpeed] = useState(0);
  const [label, setLabel] = useState("Fast");
  const [isLimited, setLimited] = useState(false);
  const [limit, setLimit] = useState(-1);

  const [isUniswap, setUniswap] = useState(true);
  const [is1inch, set1inch] = useState(false);
  const [isCow, setCow] = useState(false);

  const [priorities, setPriorities] = useState([]);

  const [jobs, setJobs] = useState([]);

  const { CopyButton } = useCopyToClipboard();
  const { isAll, selectAll } = useSelectAll();

  const handleSpeedLabelChange = (e) => {
    setLabel(e.target.value);
  };

  const fetchWallets = async () => {
    const { secondaryWallets: sWs } = await getWallets(env);
    setSecondaryWallets(sWs);
    setChecked(new Array(sWs.length | 0).fill(false));
    await fetchJobs();
  };

  const fetchJobs = async () => {
    const docs = await getJobs("liquidationModule", env);
    setJobs(docs);
  };

  const handleSellAll = async () => {
    try {
      if (!tokenAddress || tokenAddress.trim() === "") {
        toast("Enter token contract address", {
          type: "error",
        });
      } else if (!percentAmount || percentAmount < 0 || percentAmount > 100) {
        toast("Enter a valid percent amount value", {
          type: "error",
        });
      } else {
        setLoading(true);
        const trueIndexes = checked
          .map((value, index) => (value ? index : -1))
          .filter((index) => index !== -1);
        const trueIds = trueIndexes.map(
          (index) => secondaryWallets[index]?._id
        );
        let platforms = [];
        if (isUniswap) platforms.push("Uniswap");
        if (is1inch) platforms.push("1inch");
        if (isCow) platforms.push("Cow");
        await sellAllToken(
          trueIds,
          tokenAddress,
          slippage / 100,
          percentAmount,
          limit,
          speed,
          platforms,
          env,
          chainId
        );
        await fetchWallets();
        toast("Token Sell Scheduled", {
          type: "success",
        });
        setLoading(false);
      }
    } catch (e) {
      toast(e.message, {
        type: "error",
      });
      setLoading(false);
    }
  };

  const handleChange = async (e) => {
    const { id, checked } = e.target;
    setChecked((prev) => {
      prev[id] = checked;
      return [...prev];
    });
  };

  const fetchTokenInfo = async (ca) => {
    if (!ca && ca.trim() === "") return;
    try {
      const { name, symbol } = await getTokenInfo(ca, chainId);
      setName(name);
      setSymbol(symbol);
    } catch (err) {
      toast(err.message, {
        type: "error",
      });
    }
  };

  const fetchBalances = async (ca) => {
    if (!ca || ca.trim() === "") return;
    setLoading(true);
    await fetchTokenInfo(ca);
    const { secondaryBalances } = await getTokenBalances(ca, chainId, env);
    setTokenBalances(secondaryBalances);
    setLoading(false);
  };

  useEffect(() => {
    fetchWallets();
    fetchJobs();
  }, []);

  useEffect(() => {
    fetchTokenInfo(tokenAddress);
  }, [tokenAddress]);

  useEffect(() => {
    if (label) {
      if (label === "Slow") {
        setSpeed(0);
      } else if (label === "Medium") {
        setSpeed(10);
      } else if (label === "Fast") {
        setSpeed(25);
      }
    }
  }, [label]);

  useEffect(() => {
    if (isLimited) {
      setLimit(0);
    } else {
      setLimit(-1);
    }
  }, [isLimited]);

  // Group wallets
  const groups = { All: [] };
  secondaryWallets?.forEach((wallet) => {
    if (!wallet.isHidden) {
      // Add to "All" group
      groups.All.push(wallet);

      // Add to tag groups
      wallet.tags.forEach((tag) => {
        if (!groups[tag]) {
          groups[tag] = [];
        }
        groups[tag].push(wallet);
      });
    }
  });

  // Add hidden group
  groups.Hidden = secondaryWallets
    ?.filter((wallet) => wallet.isHidden)
    .map((wallet) => wallet);

  return (
    <>
      <Typography variant="h4" mb={2}>
        Liquidation Module
      </Typography>
      <Paper
        sx={{
          p: 2,
          marginBottom: "10px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={isLimited}
              onChange={() => setLimited((prev) => !prev)}
            />
          }
          label={"Limit decimals"}
        />
        <br />
        {isLimited && (
          <TextField
            label="Number of decimals"
            type="number"
            value={limit}
            onChange={(e) => setLimit(e.target.value)}
            margin="normal"
          />
        )}
        <FormControl component="fieldset" style={{ marginBottom: "15px" }}>
          <FormLabel component="legend">Select Speed</FormLabel>
          <RadioGroup
            aria-label="speed"
            name="speed"
            value={label}
            onChange={handleSpeedLabelChange}
            row
          >
            <FormControlLabel
              value="Slow"
              control={<Radio />}
              label="Slow (current)"
            />
            <FormControlLabel
              value="Medium"
              control={<Radio />}
              label="Medium (+10%)"
            />
            <FormControlLabel
              value="Fast"
              control={<Radio />}
              label="Fast (+25%)"
            />
            <FormControlLabel
              value="Custom"
              control={<Radio />}
              label="Custom"
            />
          </RadioGroup>
          {label === "Custom" && (
            <TextField
              label="Custom (%)"
              type="number"
              value={speed}
              onChange={(e) => setSpeed(e.target.value)}
              margin="normal"
              fullWidth
            />
          )}
        </FormControl>
        <div>
          <FormLabel component="legend">Swap Platforms</FormLabel>
          <FormControlLabel
            control={
              <Checkbox
                checked={isUniswap}
                onChange={() => setUniswap((prev) => !prev)}
              />
            }
            label={"Uniswap"}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={is1inch}
                onChange={() => set1inch((prev) => !prev)}
              />
            }
            label={"1inch"}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={isCow}
                onChange={() => setCow((prev) => !prev)}
              />
            }
            label={"COW"}
          />
        </div>
      </Paper>
      <Paper
        sx={{
          p: 2,
          marginBottom: "10px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid container spacing={4} padding={2}>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={async () => fetchBalances(tokenAddress)}
              disabled={isLoading}
            >
              Show/Refresh Balances
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Approval
              </AccordionSummary>
              <AccordionDetails>
                <TextField
                  value={tokenAddress}
                  variant="outlined"
                  label="Token Contract Address"
                  onChange={(e) => setTokenAddress(e.target.value)}
                  fullWidth
                  style={{ marginBottom: "5px" }}
                  helperText={`Name: ${name}, Symbol: ${symbol}`}
                />
                <TextField
                  value={spenderAddress}
                  variant="outlined"
                  label="Spender Address"
                  onChange={(e) => setSpenderAddress(e.target.value)}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={period}
                  variant="outlined"
                  label="Period (seconds)"
                  onChange={(e) => setPeriod(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={maxPeriod}
                  variant="outlined"
                  label="Max Period (seconds)"
                  onChange={(e) => setMaxPeriod(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={deviation}
                  variant="outlined"
                  label="Deviation (%)"
                  onChange={(e) => setDeviation(parseFloat(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={amount}
                  variant="outlined"
                  label="Amount"
                  onChange={(e) => setAmount(parseFloat(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <Button
                  variant="contained"
                  color="black"
                  fullWidth
                  onClick={async () => {
                    try {
                      setLoading(true);
                      const trueIndexes = checked
                        .map((value, index) => (value ? index : -1))
                        .filter((index) => index !== -1);
                      const trueIds = trueIndexes.map(
                        (index) => secondaryWallets[index]?._id
                      );
                      await approvalSchedule(
                        trueIds,
                        tokenAddress,
                        spenderAddress,
                        period,
                        maxPeriod,
                        deviation,
                        amount,
                        limit,
                        speed,
                        env,
                        chainId
                      );
                      setLoading(false);
                      toast("Approval Scheduled", {
                        type: "success",
                      });
                    } catch (e) {
                      setLoading(false);
                      toast(e.message, {
                        type: "error",
                      });
                    }
                  }}
                  disabled={isLoading}
                >
                  Schedule Approval
                </Button>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Time-based Liquidation
              </AccordionSummary>
              <AccordionDetails>
                <TextField
                  value={tokenAddress}
                  variant="outlined"
                  label="Token Contract Address"
                  onChange={(e) => setTokenAddress(e.target.value)}
                  fullWidth
                  style={{ marginBottom: "5px" }}
                  helperText={`Name: ${name}, Symbol: ${symbol}`}
                />
                <TextField
                  value={slippage}
                  variant="outlined"
                  label="Slippage (0-100%)"
                  onChange={(e) => {
                    let num = parseFloat(e.target.value);
                    num = num < 0 ? 0 : num % 101;
                    setSlippage(num);
                  }}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={period}
                  variant="outlined"
                  label="Period (seconds)"
                  onChange={(e) => setPeriod(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={maxPeriod}
                  variant="outlined"
                  label="Max Period (seconds)"
                  onChange={(e) => setMaxPeriod(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={deviation}
                  variant="outlined"
                  label="Deviation (%)"
                  onChange={(e) => setDeviation(parseFloat(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={amount}
                  variant="outlined"
                  label="Amount"
                  onChange={(e) => setAmount(parseFloat(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={maxAmount}
                  variant="outlined"
                  label="Max Amount"
                  onChange={(e) => setMaxAmount(parseFloat(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={amountDeviation}
                  variant="outlined"
                  label="Amount Deviation (%)"
                  onChange={(e) =>
                    setAmountDeviation(parseFloat(e.target.value))
                  }
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <Button
                  variant="contained"
                  color="black"
                  fullWidth
                  onClick={async () => {
                    try {
                      setLoading(true);
                      const trueIndexes = checked
                        .map((value, index) => (value ? index : -1))
                        .filter((index) => index !== -1);
                      const trueIds = trueIndexes.map(
                        (index) => secondaryWallets[index]?._id
                      );

                      let platforms = [];
                      if (isUniswap) platforms.push("Uniswap");
                      if (is1inch) platforms.push("1inch");
                      if (isCow) platforms.push("Cow");
                      await timeLiquidate(
                        trueIds,
                        tokenAddress,
                        slippage / 100,
                        period,
                        maxPeriod,
                        deviation,
                        amount,
                        maxAmount,
                        amountDeviation,
                        limit,
                        speed,
                        platforms,
                        env,
                        chainId
                      );
                      setLoading(false);
                      toast("Time-Based Liquidation Scheduled", {
                        type: "success",
                      });
                    } catch (e) {
                      setLoading(false);
                      toast(e.message, {
                        type: "error",
                      });
                    }
                  }}
                  disabled={isLoading}
                >
                  Schedule Time-based Liquidation
                </Button>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                Market-based Liquidation
              </AccordionSummary>
              <AccordionDetails>
                <TextField
                  value={tokenAddress}
                  variant="outlined"
                  label="Token Contract Address"
                  onChange={(e) => setTokenAddress(e.target.value)}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                  helperText={`Name: ${name}, Symbol: ${symbol}`}
                />
                <TextField
                  value={pairAddress}
                  variant="outlined"
                  label="Uniswap Pair Address"
                  onChange={(e) => setPairAddress(e.target.value)}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={slippage}
                  variant="outlined"
                  label="Slippage (0-100%)"
                  onChange={(e) => {
                    let num = parseFloat(e.target.value);
                    num = num < 0 ? 0 : num % 101;
                    setSlippage(num);
                  }}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={threshold}
                  variant="outlined"
                  label="Minimum Threshold for Sell"
                  onChange={(e) => setThreshold(parseFloat(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={percentAmount}
                  variant="outlined"
                  label="Amount to Sell of the Buy Order (%)"
                  onChange={(e) => setPercentAmount(parseFloat(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <Button
                  variant="contained"
                  color="black"
                  fullWidth
                  onClick={async () => {
                    try {
                      setLoading(true);
                      const trueIndexes = checked
                        .map((value, index) => (value ? index : -1))
                        .filter((index) => index !== -1);
                      const trueIds = trueIndexes.map(
                        (index) => secondaryWallets[index]?._id
                      );

                      let platforms = [];
                      if (isUniswap) platforms.push("Uniswap");
                      if (is1inch) platforms.push("1inch");
                      if (isCow) platforms.push("Cow");
                      await marketLiquidate(
                        env,
                        trueIds,
                        tokenAddress,
                        pairAddress,
                        slippage / 100,
                        threshold,
                        percentAmount,
                        limit,
                        speed,
                        platforms,
                        chainId
                      );
                      setLoading(false);
                      toast("Market-based Liquidation Scheduled", {
                        type: "success",
                      });
                    } catch (e) {
                      setLoading(false);
                      toast(e.message, {
                        type: "error",
                      });
                    }
                  }}
                  disabled={isLoading}
                >
                  Schedule Market-based Liquidation
                </Button>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                Pair Token Change-based Liquidation
              </AccordionSummary>
              <AccordionDetails>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    Choose a Template
                  </AccordionSummary>
                  <AccordionDetails>
                    {jobs
                      ?.filter((job) => {
                        return (
                          job.type === "Pair Token-Change Liquidation" &&
                          job.label
                        );
                      })
                      .map((job) => (
                        <div
                          key={job?._id}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "default",
                          }}
                          onClick={(e) => e.preventDefault()}
                        >
                          {job?.label}
                          <IconButton
                            aria-label="jobs"
                            onClick={async () => {
                              try {
                                setTokenAddress(job?.tokenAddress);
                                setPairAddress(job?.pairAddress);
                                setSlippage(
                                  parseFloat(job?.slippage || 0) * 100
                                );
                                setPrevBlockNum(job?.prevBlockNum);
                                setNumLiquidations(job?.numOfLiquidations);
                                setPriorities(job?.priorities);

                                const walletIds = job?.walletIds;
                                let newChecked = new Array(
                                  secondaryWallets.length | 0
                                ).fill(false);
                                for (let i = 0; i < walletIds?.length; i++) {
                                  const wallet = secondaryWallets.find(
                                    (sw) => sw._id === walletIds[i]
                                  );
                                  if (wallet) {
                                    newChecked[wallet?.walletIndex] = true;
                                  }
                                }
                                setChecked(newChecked);
                                toast("Fields Populated", {
                                  type: "success",
                                });
                              } catch (e) {
                                toast(e.message, {
                                  type: "error",
                                });
                              }
                            }}
                          >
                            <Add />
                          </IconButton>
                        </div>
                      ))}
                  </AccordionDetails>
                </Accordion>
                <TextField
                  value={tokenAddress}
                  variant="outlined"
                  label="Token Contract Address"
                  onChange={(e) => setTokenAddress(e.target.value)}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                  helperText={`Name: ${name}, Symbol: ${symbol}`}
                />
                <TextField
                  value={pairAddress}
                  variant="outlined"
                  label="Uniswap Pair Address"
                  onChange={(e) => setPairAddress(e.target.value)}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={slippage}
                  variant="outlined"
                  label="Slippage (0-100%)"
                  onChange={(e) => {
                    let num = parseFloat(e.target.value);
                    num = num < 0 ? 0 : num % 101;
                    setSlippage(num);
                  }}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={prevBlockNum}
                  variant="outlined"
                  label="Number of previous blocks to track"
                  onChange={(e) => setPrevBlockNum(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={numOfLiquidations}
                  variant="outlined"
                  label="Number of Liquidations"
                  onChange={(e) => setNumLiquidations(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={priorities?.length || 0}
                  variant="outlined"
                  label="Number of priority items"
                  onChange={(e) => {
                    const numOfPriorities = parseInt(e.target.value);
                    if (numOfLiquidations > -1) {
                      setPriorities(
                        Array.from({ length: numOfPriorities }, (_, index) => ({
                          priority: index,
                          threshold: 0,
                          percentAmount: 0,
                          threshold2: 0,
                          maxAmount: 0,
                        }))
                      );
                    }
                  }}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                {priorities.map((item, index) => (
                  <div key={item?.priority}>
                    <p>Priority {item?.priority}</p>
                    <TextField
                      value={item?.threshold}
                      variant="outlined"
                      label="Minimum Threshold for Sell (token1)"
                      onChange={(e) =>
                        setPriorities((prev) => {
                          let temp = prev[index];
                          temp.threshold = parseFloat(e.target.value);
                          prev[index] = temp;
                          return [...prev];
                        })
                      }
                      fullWidth
                      style={{ marginBottom: "15px" }}
                    />
                    <TextField
                      value={item?.threshold2}
                      variant="outlined"
                      label="Minimum Price Threshold for Sell (token1)"
                      onChange={(e) =>
                        setPriorities((prev) => {
                          let temp = prev[index];
                          temp.threshold2 = parseFloat(e.target.value);
                          prev[index] = temp;
                          return [...prev];
                        })
                      }
                      fullWidth
                      style={{ marginBottom: "15px" }}
                    />
                    <TextField
                      value={item?.percentAmount}
                      variant="outlined"
                      label="Amount to Sell of the Buy Order (%)"
                      onChange={(e) =>
                        setPriorities((prev) => {
                          let temp = prev[index];
                          temp.percentAmount = parseFloat(e.target.value);
                          prev[index] = temp;
                          return [...prev];
                        })
                      }
                      fullWidth
                      style={{ marginBottom: "15px" }}
                    />
                    <TextField
                      value={item?.maxAmount}
                      variant="outlined"
                      label="Max Sell Amount (token1)"
                      onChange={(e) =>
                        setPriorities((prev) => {
                          let temp = prev[index];
                          temp.maxAmount = parseFloat(e.target.value);
                          prev[index] = temp;
                          return [...prev];
                        })
                      }
                      fullWidth
                      style={{ marginBottom: "15px" }}
                    />
                  </div>
                ))}
                <Button
                  variant="contained"
                  color="black"
                  fullWidth
                  onClick={async () => {
                    try {
                      setLoading(true);
                      const trueIndexes = checked
                        .map((value, index) => (value ? index : -1))
                        .filter((index) => index !== -1);
                      const trueIds = trueIndexes.map(
                        (index) => secondaryWallets[index]?._id
                      );
                      let platforms = [];
                      if (isUniswap) platforms.push("Uniswap");
                      if (is1inch) platforms.push("1inch");
                      if (isCow) platforms.push("Cow");
                      await marketLiquidate(
                        env,
                        trueIds,
                        tokenAddress,
                        pairAddress,
                        slippage / 100,
                        threshold,
                        percentAmount,
                        limit,
                        speed,
                        platforms,
                        chainId,
                        "Pair Token-Change Liquidation",
                        0,
                        0,
                        0,
                        0,
                        0,
                        0,
                        prevBlockNum,
                        numOfLiquidations,
                        priorities
                      );
                      setLoading(false);
                      toast("Pair Token Change-based Liquidation Scheduled", {
                        type: "success",
                      });
                    } catch (e) {
                      setLoading(false);
                      toast(e.message, {
                        type: "error",
                      });
                    }
                  }}
                  disabled={isLoading}
                >
                  Schedule Pair Token Change-based Liquidation
                </Button>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    Add Jobs to Templates
                  </AccordionSummary>
                  <AccordionDetails>
                    {jobs
                      ?.filter((job) => {
                        return (
                          job.type === "Pair Token-Change Liquidation" &&
                          !job.label
                        );
                      })
                      .map((job) => (
                        <div
                          key={job?._id}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            cursor: "default",
                          }}
                          onClick={(e) => e.preventDefault()}
                        >
                          {job?._id}
                          <IconButton
                            aria-label="jobs"
                            onClick={async () => {
                              try {
                                const userInput = window.prompt("Give a label");
                                await addTemplate(
                                  job?._id,
                                  "liquidationModule",
                                  userInput
                                );
                                await fetchJobs();
                                toast("Label Added", {
                                  type: "success",
                                });
                              } catch (e) {
                                toast(e.message, {
                                  type: "error",
                                });
                              }
                            }}
                          >
                            <Add />
                          </IconButton>
                        </div>
                      ))}
                  </AccordionDetails>
                </Accordion>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                Price-based Liquidation
              </AccordionSummary>
              <AccordionDetails>
                <TextField
                  value={tokenAddress}
                  variant="outlined"
                  label="Token Contract Address"
                  onChange={(e) => setTokenAddress(e.target.value)}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                  helperText={`Name: ${name}, Symbol: ${symbol}`}
                />
                <TextField
                  value={pairAddress}
                  variant="outlined"
                  label="Uniswap Pair Address"
                  onChange={(e) => setPairAddress(e.target.value)}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={slippage}
                  variant="outlined"
                  label="Slippage (0-100%)"
                  onChange={(e) => {
                    let num = parseFloat(e.target.value);
                    num = num < 0 ? 0 : num % 101;
                    setSlippage(num);
                  }}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={threshold}
                  variant="outlined"
                  label="Minimum Price Threshold for Sell in terms of token1"
                  onChange={(e) => setThreshold(parseFloat(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={period}
                  variant="outlined"
                  label="Period (seconds)"
                  onChange={(e) => setPeriod(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={maxPeriod}
                  variant="outlined"
                  label="Max Period (seconds)"
                  onChange={(e) => setMaxPeriod(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={deviation}
                  variant="outlined"
                  label="Deviation (%)"
                  onChange={(e) => setDeviation(parseFloat(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={amount}
                  variant="outlined"
                  label="Amount"
                  onChange={(e) => setAmount(parseFloat(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={maxAmount}
                  variant="outlined"
                  label="Max Amount"
                  onChange={(e) => setMaxAmount(parseFloat(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={amountDeviation}
                  variant="outlined"
                  label="Amount Deviation (%)"
                  onChange={(e) =>
                    setAmountDeviation(parseFloat(e.target.value))
                  }
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <Button
                  variant="contained"
                  color="black"
                  fullWidth
                  onClick={async () => {
                    try {
                      setLoading(true);
                      const trueIndexes = checked
                        .map((value, index) => (value ? index : -1))
                        .filter((index) => index !== -1);
                      const trueIds = trueIndexes.map(
                        (index) => secondaryWallets[index]?._id
                      );
                      let platforms = [];
                      if (isUniswap) platforms.push("Uniswap");
                      if (is1inch) platforms.push("1inch");
                      if (isCow) platforms.push("Cow");
                      await marketLiquidate(
                        env,
                        trueIds,
                        tokenAddress,
                        pairAddress,
                        slippage / 100,
                        threshold,
                        0,
                        limit,
                        speed,
                        platforms,
                        chainId,
                        "Price Liquidation",
                        period,
                        maxPeriod,
                        deviation,
                        amount,
                        maxAmount,
                        amountDeviation
                      );
                      setLoading(false);
                      toast("Price-based Liquidation Scheduled", {
                        type: "success",
                      });
                    } catch (e) {
                      setLoading(false);
                      toast(e.message, {
                        type: "error",
                      });
                    }
                  }}
                  disabled={isLoading}
                >
                  Schedule Price-based Liquidation
                </Button>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                Price-based Accumulation
              </AccordionSummary>
              <AccordionDetails>
                <TextField
                  value={tokenAddress}
                  variant="outlined"
                  label="Token Contract Address"
                  onChange={(e) => setTokenAddress(e.target.value)}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                  helperText={`Name: ${name}, Symbol: ${symbol}`}
                />
                <TextField
                  value={pairAddress}
                  variant="outlined"
                  label="Uniswap Pair Address"
                  onChange={(e) => setPairAddress(e.target.value)}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={slippage}
                  variant="outlined"
                  label="Slippage (0-100%)"
                  onChange={(e) => {
                    let num = parseFloat(e.target.value);
                    num = num < 0 ? 0 : num % 101;
                    setSlippage(num);
                  }}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={threshold}
                  variant="outlined"
                  label="Minimum Price Threshold for Buy in terms of token1"
                  onChange={(e) => setThreshold(parseFloat(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={period}
                  variant="outlined"
                  label="Period (seconds)"
                  onChange={(e) => setPeriod(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={maxPeriod}
                  variant="outlined"
                  label="Max Period (seconds)"
                  onChange={(e) => setMaxPeriod(parseInt(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={deviation}
                  variant="outlined"
                  label="Deviation (%)"
                  onChange={(e) => setDeviation(parseFloat(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={amount}
                  variant="outlined"
                  label="Amount"
                  onChange={(e) => setAmount(parseFloat(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={maxAmount}
                  variant="outlined"
                  label="Max Amount"
                  onChange={(e) => setMaxAmount(parseFloat(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={amountDeviation}
                  variant="outlined"
                  label="Amount Deviation (%)"
                  onChange={(e) =>
                    setAmountDeviation(parseFloat(e.target.value))
                  }
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <Button
                  variant="contained"
                  color="black"
                  fullWidth
                  onClick={async () => {
                    try {
                      setLoading(true);
                      const trueIndexes = checked
                        .map((value, index) => (value ? index : -1))
                        .filter((index) => index !== -1);
                      const trueIds = trueIndexes.map(
                        (index) => secondaryWallets[index]?._id
                      );
                      let platforms = [];
                      if (isUniswap) platforms.push("Uniswap");
                      if (is1inch) platforms.push("1inch");
                      if (isCow) platforms.push("Cow");
                      await marketLiquidate(
                        env,
                        trueIds,
                        tokenAddress,
                        pairAddress,
                        slippage / 100,
                        threshold,
                        0,
                        limit,
                        speed,
                        platforms,
                        chainId,
                        "Price Accumulation",
                        period,
                        maxPeriod,
                        deviation,
                        amount,
                        maxAmount,
                        amountDeviation
                      );
                      setLoading(false);
                      toast("Price-based Accumulation Scheduled", {
                        type: "success",
                      });
                    } catch (e) {
                      setLoading(false);
                      toast(e.message, {
                        type: "error",
                      });
                    }
                  }}
                  disabled={isLoading}
                >
                  Schedule Price-based Accumulation
                </Button>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                Sell All
              </AccordionSummary>
              <AccordionDetails>
                <TextField
                  value={tokenAddress}
                  variant="outlined"
                  label="Token Contract Address"
                  onChange={(e) => setTokenAddress(e.target.value)}
                  fullWidth
                  helperText={`Name: ${name}, Symbol: ${symbol}`}
                  style={{ marginBottom: "5px" }}
                />
                <TextField
                  value={percentAmount}
                  variant="outlined"
                  label="Amount to sell (%)"
                  onChange={(e) => setPercentAmount(parseFloat(e.target.value))}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <TextField
                  value={slippage}
                  variant="outlined"
                  label="Slippage (0-100%)"
                  onChange={(e) => {
                    let num = parseFloat(e.target.value);
                    num = num < 0 ? 0 : num % 101;
                    setSlippage(num);
                  }}
                  fullWidth
                  style={{ marginBottom: "15px" }}
                />
                <Button
                  variant="contained"
                  color="black"
                  fullWidth
                  onClick={async () => handleSellAll()}
                  disabled={isLoading}
                >
                  Sell From All Selected Wallets
                </Button>
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormGroup>
              {Object.entries(groups)?.map(([groupName, groupWallets]) => (
                <Accordion key={groupName}>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    {groupName}
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isAll(groupWallets, checked)}
                          onChange={() =>
                            selectAll(groupWallets, checked, setChecked)
                          }
                        />
                      }
                      label={"Select All"}
                      style={{ marginBottom: "5px" }}
                    />
                    {groupWallets?.map((sW) => (
                      <div key={sW?.walletIndex}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              id={sW?.walletIndex}
                              checked={checked[sW?.walletIndex]}
                              onChange={handleChange}
                            />
                          }
                          label={
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "default",
                              }}
                              onClick={(e) => e.preventDefault()}
                            >
                              {sW?.address}
                              <CopyButton text={sW?.address} />
                            </div>
                          }
                        />
                        <FormHelperText>{`Balance: ${
                          tokenBalances ? tokenBalances[sW?.walletIndex] : "-"
                        } ${symbol}`}</FormHelperText>
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))}
            </FormGroup>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default Liquidation;
