import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { ExpandMore } from "@mui/icons-material";
import { io } from "socket.io-client";

function Logs() {
  const [iLogs, setInternalLogs] = useState(null);
  const [eLogs, setErrorLogs] = useState(null);
  const [dLogs, setDebugLogs] = useState(null);

  useEffect(() => {
    // Use the same hostname as the page's URL
    const socket = io();

    socket.on("connect", () => {
      console.log("Connected to WebSocket server");
    });

    socket.on("logs", (data) => {
      switch (data.label) {
        case "internalLogs":
          setInternalLogs(data.logs);
          break;
        case "errorLogs":
          setErrorLogs(data.logs);
          break;
        case "debugLogs":
          setDebugLogs(data.logs);
          break;
        default:
          console.warn("Unknown log type:", data.label);
      }
    });

    socket.on("disconnect", () => {
      console.log("Disconnected from WebSocket server");
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <>
      <Typography variant="h4" mb={2}>
        Logs
      </Typography>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Server Error Logs
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ maxHeight: "70vh", overflow: "auto" }}>
            <Typography variant="body2">
              <pre style={{ whiteSpace: "pre-wrap" }}>
                {eLogs || "Loading Error logs..."}
              </pre>
            </Typography>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Server Debug Logs
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ maxHeight: "70vh", overflow: "auto" }}>
            <Typography variant="body2">
              <pre style={{ whiteSpace: "pre-wrap" }}>
                {dLogs || "Loading Debug logs..."}
              </pre>
            </Typography>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Server Internal Logs
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ maxHeight: "60vh", overflow: "auto" }}>
            <Typography variant="body2">
              <pre style={{ whiteSpace: "pre-wrap" }}>
                {iLogs || "Loading Internal logs..."}
              </pre>
            </Typography>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default Logs;
