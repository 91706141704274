import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  accumulate,
  getWallets,
  getTokenBalances,
  getTokenInfo,
} from "../../api/api";
import { toast } from "react-toastify";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import { ExpandMore } from "@mui/icons-material";
import useSelectAll from "../../hooks/useSelectAll";

function Accumulation({ env, chainId }) {
  const [secondaryWallets, setSecondaryWallets] = useState(null);
  const [tokenAddress, setTokenAddress] = useState("");
  const [slippage, setSlippage] = useState(0);
  const [period, setPeriod] = useState(0);
  const [maxPeriod, setMaxPeriod] = useState(0);
  const [deviation, setDeviation] = useState(0);
  const [checked, setChecked] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isPrioritized, setPrioritized] = useState(false);
  const [isUniswap, setUniswap] = useState(false);
  const [is1inch, set1inch] = useState(false);
  const [isCow, setCow] = useState(false);
  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [maxAmount, setMaxAmount] = useState(0);
  const [amountDeviation, setAmountDeviation] = useState(0);
  const [maxHolding, setMaxHolding] = useState(0);
  const [retentionAmount, setRetentionAmount] = useState(0);
  // Secondary Wallets
  const [tokenBalances, setTokenBalances] = useState(null);

  const [speed, setSpeed] = useState(0);
  const [label, setLabel] = useState("Slow");
  const [isLimited, setLimited] = useState(false);
  const [limit, setLimit] = useState(-1);

  const { CopyButton } = useCopyToClipboard();
  const { isAll, selectAll } = useSelectAll();

  const handleSpeedLabelChange = (e) => {
    setLabel(e.target.value);
  };

  const fetchWallets = async () => {
    const { secondaryWallets: sWs } = await getWallets(env);
    setSecondaryWallets(sWs);
    setChecked(new Array(sWs.length | 0).fill(false));
  };

  const handleChange = async (e) => {
    const { id, checked } = e.target;
    setChecked((prev) => {
      prev[id] = checked;
      return [...prev];
    });
  };

  const fetchBalances = async () => {
    if (!tokenAddress || tokenAddress.trim() === "") return;
    setLoading(true);
    const { secondaryBalances } = await getTokenBalances(
      tokenAddress,
      chainId,
      env
    );
    setTokenBalances(secondaryBalances);
    setLoading(false);
  };

  const fetchTokenInfo = async () => {
    try {
      const { name, symbol } = await getTokenInfo(tokenAddress, chainId);
      setName(name);
      setSymbol(symbol);
    } catch (err) {
      toast(err.message, {
        type: "error",
      });
    }
  };

  useEffect(() => {
    fetchWallets();
  }, []);

  useEffect(() => {
    if (tokenAddress && tokenAddress.trim() !== "") {
      fetchTokenInfo();
    }
  }, [tokenAddress]);

  useEffect(() => {
    if (label) {
      if (label === "Slow") {
        setSpeed(0);
      } else if (label === "Medium") {
        setSpeed(10);
      } else if (label === "Fast") {
        setSpeed(25);
      }
    }
  }, [label]);

  useEffect(() => {
    if (isLimited) {
      setLimit(0);
    } else {
      setLimit(-1);
    }
  }, [isLimited]);

  // Group wallets
  const groups = { All: [] };
  secondaryWallets?.forEach((wallet) => {
    if (!wallet.isHidden) {
      // Add to "All" group
      groups.All.push(wallet);

      // Add to tag groups
      wallet.tags.forEach((tag) => {
        if (!groups[tag]) {
          groups[tag] = [];
        }
        groups[tag].push(wallet);
      });
    }
  });

  // Add hidden group
  groups.Hidden = secondaryWallets
    ?.filter((wallet) => wallet.isHidden)
    .map((wallet) => wallet);

  return (
    <>
      <Typography variant="h4" mb={2}>
        Accumulation Module
      </Typography>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid container spacing={2} padding={2}>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={async () => fetchBalances()}
              disabled={isLoading}
            >
              Show/Refresh Balances
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              value={tokenAddress}
              variant="outlined"
              label="Token Contract Address"
              onChange={(e) => setTokenAddress(e.target.value)}
              fullWidth
              style={{ marginBottom: "5px" }}
              helperText={`Name: ${name}, Symbol: ${symbol}`}
            />
            <TextField
              value={slippage}
              variant="outlined"
              label="Slippage (0-100%)"
              onChange={(e) => {
                let num = parseFloat(e.target.value);
                num = num < 0 ? 0 : num % 101;
                setSlippage(num);
              }}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <TextField
              value={period}
              variant="outlined"
              label="Period (seconds)"
              onChange={(e) => setPeriod(parseInt(e.target.value))}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <TextField
              value={maxPeriod}
              variant="outlined"
              label="Max Period (seconds)"
              onChange={(e) => setMaxPeriod(parseInt(e.target.value))}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <TextField
              value={deviation}
              variant="outlined"
              label="Deviation (%)"
              onChange={(e) => setDeviation(parseFloat(e.target.value))}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <TextField
              value={totalAmount}
              variant="outlined"
              label="Total Amount"
              onChange={(e) => {
                setTotalAmount(parseFloat(e.target.value));
                const trueIndexes = checked
                  .map((value, index) => (value ? index : -1))
                  .filter((index) => index !== -1);

                const numOfWallets = trueIndexes.length;
                if (numOfWallets > 0) {
                  setAmount(parseFloat(e.target.value) / numOfWallets);
                }
              }}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <TextField
              value={amount}
              variant="outlined"
              label="Average Amount"
              onChange={(e) => {
                setAmount(parseFloat(e.target.value));
                const trueIndexes = checked
                  .map((value, index) => (value ? index : -1))
                  .filter((index) => index !== -1);

                const numOfWallets = trueIndexes.length;
                if (numOfWallets > 0) {
                  setTotalAmount(parseFloat(e.target.value) * numOfWallets);
                }
              }}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <TextField
              value={maxAmount}
              variant="outlined"
              label="Max Amount"
              onChange={(e) => setMaxAmount(parseFloat(e.target.value))}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <TextField
              value={amountDeviation}
              variant="outlined"
              label="Amount Deviation (%)"
              onChange={(e) => setAmountDeviation(parseFloat(e.target.value))}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <TextField
              value={maxHolding}
              variant="outlined"
              label="Max Holding Limit"
              onChange={(e) => setMaxHolding(parseFloat(e.target.value))}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <TextField
              value={retentionAmount}
              variant="outlined"
              label="Retention Amount (ETH)"
              onChange={(e) => setRetentionAmount(parseFloat(e.target.value))}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isPrioritized}
                  onChange={() => setPrioritized((prev) => !prev)}
                />
              }
              label={"Prioritize order of purchases"}
              style={{ marginBottom: "5px" }}
            />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isLimited}
                  onChange={() => setLimited((prev) => !prev)}
                />
              }
              label={"Limit decimals"}
            />
            <br />
            {isLimited && (
              <TextField
                label="Number of decimals"
                type="number"
                value={limit}
                onChange={(e) => setLimit(e.target.value)}
                margin="normal"
              />
            )}
            <div>
              <FormLabel component="legend">Swap Platforms</FormLabel>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isUniswap}
                    onChange={() => setUniswap((prev) => !prev)}
                  />
                }
                label={"Uniswap"}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={is1inch}
                    onChange={() => set1inch((prev) => !prev)}
                  />
                }
                label={"1inch"}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCow}
                    onChange={() => setCow((prev) => !prev)}
                  />
                }
                label={"COW"}
              />
            </div>
            <FormControl component="fieldset" style={{ marginBottom: "15px" }}>
              <FormLabel component="legend">Select Speed</FormLabel>
              <RadioGroup
                aria-label="speed"
                name="speed"
                value={label}
                onChange={handleSpeedLabelChange}
                row
              >
                <FormControlLabel
                  value="Slow"
                  control={<Radio />}
                  label="Slow (current)"
                />
                <FormControlLabel
                  value="Medium"
                  control={<Radio />}
                  label="Medium (+10%)"
                />
                <FormControlLabel
                  value="Fast"
                  control={<Radio />}
                  label="Fast (+25%)"
                />
                <FormControlLabel
                  value="Custom"
                  control={<Radio />}
                  label="Custom"
                />
              </RadioGroup>
              {label === "Custom" && (
                <TextField
                  label="Custom (%)"
                  type="number"
                  value={speed}
                  onChange={(e) => setSpeed(e.target.value)}
                  margin="normal"
                  fullWidth
                />
              )}
            </FormControl>
            <Button
              variant="contained"
              color="black"
              fullWidth
              onClick={async () => {
                try {
                  setLoading(true);
                  const trueIndexes = checked
                    .map((value, index) => (value ? index : -1))
                    .filter((index) => index !== -1);
                  const trueIds = trueIndexes.map(
                    (index) => secondaryWallets[index]?._id
                  );
                  let platforms = [];
                  if (isUniswap) platforms.push("Uniswap");
                  if (is1inch) platforms.push("1inch");
                  if (isCow) platforms.push("Cow");
                  await accumulate(
                    trueIds,
                    tokenAddress,
                    slippage / 100,
                    period,
                    maxPeriod,
                    deviation,
                    amount,
                    maxAmount,
                    amountDeviation,
                    maxHolding,
                    retentionAmount,
                    isPrioritized,
                    limit,
                    speed,
                    platforms,
                    env,
                    chainId
                  );
                  setLoading(false);
                  toast("Accumulation Scheduled", {
                    type: "success",
                  });
                } catch (e) {
                  setLoading(false);
                  toast(e.message, {
                    type: "error",
                  });
                }
              }}
              disabled={isLoading}
            >
              Schedule Accumulation
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormGroup>
              {Object.entries(groups)?.map(([groupName, groupWallets]) => (
                <Accordion key={groupName}>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    {groupName}
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isAll(groupWallets, checked)}
                          onChange={() =>
                            selectAll(groupWallets, checked, setChecked)
                          }
                        />
                      }
                      label={"Select All"}
                      style={{ marginBottom: "5px" }}
                    />
                    {groupWallets?.map((sW) => (
                      <div key={sW?.walletIndex}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              id={sW?.walletIndex}
                              checked={checked[sW?.walletIndex]}
                              onChange={handleChange}
                            />
                          }
                          label={
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "default",
                              }}
                              onClick={(e) => e.preventDefault()}
                            >
                              {sW?.address}
                              <CopyButton text={sW?.address} />
                            </div>
                          }
                        />
                        <FormHelperText>{`Balance: ${
                          tokenBalances ? tokenBalances[sW?.walletIndex] : "-"
                        } ${symbol}`}</FormHelperText>
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))}
            </FormGroup>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default Accumulation;
