import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  getEthBalances,
  getWallets,
  getTokenInfo,
  launch,
  stopLaunch,
} from "../../api/api";
import { toast } from "react-toastify";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import { ExpandMore } from "@mui/icons-material";
import useSelectAll from "../../hooks/useSelectAll";
import Papa from "papaparse";

function separateFunctions(abi) {
  if (!abi || abi === undefined || abi === "") abi = [];
  const readFunctions = [];
  const writeFunctions = [];

  abi.forEach((item) => {
    if (item.type === "function") {
      const functionDetails = {
        name: item.name,
        inputs: item.inputs.map((input) => ({
          name: input.name,
          type: input.type,
        })),
      };

      if (item.stateMutability === "view" || item.stateMutability === "pure") {
        readFunctions.push(functionDetails);
      } else {
        writeFunctions.push(functionDetails);
      }
    }
  });

  return { readFunctions, writeFunctions };
}

function Launch({ env, chainId }) {
  const [retries, setRetries] = useState(0);
  const [secondaryWallets, setSecondaryWallets] = useState(null);
  const [tokenAddress, setTokenAddress] = useState("");
  const [pairAddress, setPairAddress] = useState("");
  const [priorityFee, setPriorityFee] = useState(0);
  const [priorityFeeOthers, setPriorityFeeOthers] = useState(0);
  const [addOnFee, setAddOnFee] = useState(0);
  const [addOnFeeOthers, setAddOnFeeOthers] = useState(0);
  const [enableTradingGasLimit, setEnableTradingGasLimit] = useState(0);
  const [swapGasLimit, setSwapGasLimit] = useState(0);
  const [waitBlocks, setWaitBlocks] = useState(0);
  const [checked, setChecked] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [abi, setAbi] = useState([]);
  const [readFunctions, setReadFunctions] = useState([]);
  const [readInputs, setReadInputs] = useState([]);
  const [writeFunctions, setWriteFunctions] = useState([]);
  const [writeInputs, setWriteInputs] = useState([]);
  // Secondary Wallets
  const [tokenBalances, setTokenBalances] = useState(null);
  const [tokenAmounts, setTokenAmounts] = useState([]);

  const [isGlobal, setGlobal] = useState(false);
  const [globalTokenValue, setGlobalTokenValue] = useState(-1);
  const [isPrioritized, setPrioritized] = useState(false);

  const [tradingStatus, setTradingStatus] = useState({});
  const [tradingTrigger, setTradingTrigger] = useState({});

  const [primaryPk, setPrimaryPk] = useState("");

  const [simulationOnly, setSimulationOnly] = useState(false);
  const [overFundPercent, setOverFundPercent] = useState(10);

  const { CopyButton } = useCopyToClipboard();
  const { isAll, selectAll } = useSelectAll();

  const [walletAmountsData, setWalletAmountsData] = useState([]);
  const fileInputRef2 = useRef(null);

  useEffect(() => {
    if (walletAmountsData?.length > 0) {
      const n = walletAmountsData?.length;
      let newChecked = checked;
      let newTokenAmounts = tokenAmounts;
      for (let i = 0; i < n; i++) {
        try {
          const { wallet, amount } = walletAmountsData[i];
          const index = getWalletIndex(wallet);
          if (index !== -1) {
            newChecked[index] = true;
            newTokenAmounts[index] = parseFloat(amount);
          }
        } catch (err) {
          console.error(err.message);
        }
      }
      setChecked(newChecked.slice());
      setTokenAmounts(newTokenAmounts.slice());
    }
  }, [walletAmountsData]);

  const getWalletIndex = (address) => {
    const index = secondaryWallets.findIndex(
      (wallet) => wallet.address === address.toLowerCase()
    );
    return index === -1 ? -1 : secondaryWallets[index].walletIndex;
  };

  const handleWalletAmountsFileUpload = (event) => {
    setLoading(false);
    const file = event.target.files[0];

    if (file) {
      Papa.parse(file, {
        header: true, // Parse the header row to extract column names
        skipEmptyLines: true, // Skip empty lines in the CSV file
        complete: function (result) {
          setWalletAmountsData(result.data);
          // Clear the file input after the state has been updated
          fileInputRef2.current.value = null;
        },
      });
    }
  };

  const fetchWallets = async () => {
    const { secondaryWallets: sWs } = await getWallets(env);
    setSecondaryWallets(sWs);
    setChecked(new Array(sWs.length | 0).fill(false));
    setTokenAmounts(new Array(sWs.length | 0).fill(0));
  };

  const handleChange = async (e) => {
    const { id, checked } = e.target;
    setChecked((prev) => {
      prev[id] = checked;
      return [...prev];
    });
  };

  const fetchBalances = async () => {
    setLoading(true);
    const { secondaryBalances } = await getEthBalances(chainId, env);
    setTokenBalances(secondaryBalances);
    setLoading(false);
  };

  const fetchTokenInfo = async () => {
    try {
      const { name, symbol } = await getTokenInfo(tokenAddress, chainId);
      setName(name);
      setSymbol(symbol);
    } catch (err) {
      toast(err.message, {
        type: "error",
      });
    }
  };

  useEffect(() => {
    fetchWallets();
  }, []);

  useEffect(() => {
    if (tokenAddress && tokenAddress.trim() !== "") {
      fetchTokenInfo();
    }
  }, [tokenAddress]);

  useEffect(() => {
    if (isGlobal) {
      setGlobalTokenValue(0);
    } else {
      setGlobalTokenValue(-1);
    }
  }, [isGlobal]);

  useEffect(() => {
    const { readFunctions, writeFunctions } = separateFunctions(abi);
    setReadFunctions(readFunctions);
    setWriteFunctions(writeFunctions);
  }, [abi]);

  // Group wallets
  const groups = { All: [] };
  secondaryWallets?.forEach((wallet) => {
    if (!wallet.isHidden) {
      // Add to "All" group
      groups.All.push(wallet);

      // Add to tag groups
      wallet.tags.forEach((tag) => {
        if (!groups[tag]) {
          groups[tag] = [];
        }
        groups[tag].push(wallet);
      });
    }
  });

  // Add hidden group
  groups.Hidden = secondaryWallets
    ?.filter((wallet) => wallet.isHidden)
    .map((wallet) => wallet);

  return (
    <>
      <Typography variant="h4" mb={2}>
        Launch Module
      </Typography>
      <Paper
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid container spacing={2} padding={2}>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={async () => fetchBalances()}
              disabled={isLoading}
            >
              Show/Refresh Balances
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              value={tokenAddress}
              variant="outlined"
              label="Token Contract Address"
              onChange={(e) => setTokenAddress(e.target.value)}
              fullWidth
              style={{ marginBottom: "5px" }}
              helperText={`Name: ${name}, Symbol: ${symbol}`}
            />
            <TextField
              value={pairAddress}
              variant="outlined"
              label="Uniswap Pair Address"
              onChange={(e) => setPairAddress(e.target.value)}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <TextField
              value={primaryPk}
              variant="outlined"
              label="Enable Trading pk"
              onChange={(e) => setPrimaryPk(e.target.value)}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <TextField
              value={priorityFee}
              variant="outlined"
              label="First tx Priority Fee (gwei)"
              onChange={(e) => setPriorityFee(parseInt(e.target.value))}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <TextField
              value={priorityFeeOthers}
              variant="outlined"
              label="Remaining txs Priority Fee (gwei)"
              onChange={(e) => setPriorityFeeOthers(parseInt(e.target.value))}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <TextField
              value={addOnFee}
              variant="outlined"
              label="First tx Add On Fee (gwei)"
              onChange={(e) => setAddOnFee(parseInt(e.target.value))}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <TextField
              value={addOnFeeOthers}
              variant="outlined"
              label="Remaining txs Add on Fee (gwei)"
              onChange={(e) => setAddOnFeeOthers(parseInt(e.target.value))}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <TextField
              value={enableTradingGasLimit}
              variant="outlined"
              label="First tx gas limit"
              onChange={(e) =>
                setEnableTradingGasLimit(parseInt(e.target.value))
              }
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <TextField
              value={swapGasLimit}
              variant="outlined"
              label="Remaining txs gas limit"
              onChange={(e) => setSwapGasLimit(parseInt(e.target.value))}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <TextField
              value={waitBlocks}
              variant="outlined"
              label="Number of blocks to wait"
              onChange={(e) => setWaitBlocks(parseInt(e.target.value))}
              fullWidth
              style={{ marginBottom: "10px" }}
            />
            <TextField
              value={overFundPercent}
              variant="outlined"
              label="Over Fund Amount (%)"
              onChange={(e) => setOverFundPercent(parseInt(e.target.value))}
              fullWidth
              style={{ marginBottom: "10px" }}
            />
            <TextField
              value={retries}
              variant="outlined"
              label="Number of retries"
              onChange={(e) => setRetries(parseInt(e.target.value))}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={simulationOnly}
                  onChange={() => setSimulationOnly((prev) => !prev)}
                />
              }
              label={"Simulaton Only"}
              style={{ marginBottom: "5px" }}
            />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isPrioritized}
                  onChange={() => setPrioritized((prev) => !prev)}
                />
              }
              label={"Prioritize order of purchases"}
              style={{ marginBottom: "5px" }}
            />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isGlobal}
                  onChange={() => setGlobal((prev) => !prev)}
                />
              }
              label={"Global token value"}
              style={{ marginBottom: "15px" }}
            />
            <br />
            {isGlobal && (
              <TextField
                label="Number of tokens to purchase"
                variant="outlined"
                value={globalTokenValue}
                onChange={(e) =>
                  setGlobalTokenValue(parseFloat(e.target.value))
                }
                style={{ marginBottom: "15px" }}
              />
            )}
            <TextField
              value={JSON.stringify(abi)}
              variant="outlined"
              label="ABI (JSON Array)"
              onChange={(e) => {
                try {
                  setAbi(JSON.parse(e.target.value));
                } catch (err) {
                  toast("Please input a valid abi", {
                    type: "error",
                  });
                }
              }}
              fullWidth
              style={{ marginBottom: "15px" }}
            />
            {abi && abi.length > 0 && (
              <>
                <FormControl fullWidth style={{ marginBottom: "15px" }}>
                  <InputLabel>Trading Status function</InputLabel>
                  <Select
                    value={tradingStatus}
                    label="Trading Status function"
                    onChange={(e) => {
                      setTradingStatus(e.target.value);
                      setReadInputs([]);
                    }}
                  >
                    {readFunctions.map((fun) => (
                      <MenuItem key={fun.name} value={fun}>
                        {fun.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {tradingStatus?.inputs?.map((inp, index) => {
                  return (
                    <TextField
                      value={readInputs[index]}
                      variant="outlined"
                      label={`${inp.name} (${inp.type})`}
                      onChange={(e) => {
                        let newInputs = readInputs;
                        newInputs[index] = e.target.value;
                        setReadInputs(newInputs);
                      }}
                      style={{ marginBottom: "15px" }}
                    />
                  );
                })}
                <FormControl fullWidth style={{ marginBottom: "15px" }}>
                  <InputLabel>Trading Trigger function</InputLabel>
                  <Select
                    value={tradingTrigger}
                    label="Trading Trigger function"
                    onChange={(e) => {
                      setTradingTrigger(e.target.value);
                      setWriteInputs([]);
                    }}
                  >
                    {writeFunctions.map((fun) => (
                      <MenuItem key={fun.name} value={fun}>
                        {fun.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {tradingTrigger?.inputs?.map((inp, index) => {
                  return (
                    <TextField
                      value={writeInputs[index]}
                      variant="outlined"
                      label={`${inp.name} (${inp.type})`}
                      onChange={(e) => {
                        let newInputs = writeInputs;
                        newInputs[index] = e.target.value;
                        setWriteInputs(newInputs);
                      }}
                      style={{ marginBottom: "15px" }}
                    />
                  );
                })}
              </>
            )}
            <Button
              variant="contained"
              color="black"
              fullWidth
              onClick={async () => {
                try {
                  setLoading(true);
                  const trueIndexes = checked
                    .map((value, index) => (value ? index : -1))
                    .filter((index) => index !== -1);

                  const globalTokenAmounts = new Array(trueIndexes.length).fill(
                    globalTokenValue
                  );
                  const selectedTokenAmounts = trueIndexes.map(
                    (index) => tokenAmounts[index]
                  );
                  const trueIds = trueIndexes.map(
                    (index) => secondaryWallets[index]?._id
                  );
                  const filterABI = abi.filter(
                    (item) =>
                      item.name == tradingStatus?.name ||
                      item.name == tradingTrigger?.name
                  );
                  await launch(
                    tokenAddress,
                    pairAddress,
                    isGlobal ? globalTokenAmounts : selectedTokenAmounts,
                    priorityFee,
                    priorityFeeOthers,
                    addOnFee,
                    addOnFeeOthers,
                    enableTradingGasLimit,
                    swapGasLimit,
                    trueIds,
                    tradingStatus?.name,
                    readInputs,
                    tradingTrigger?.name,
                    writeInputs,
                    filterABI,
                    waitBlocks,
                    primaryPk,
                    isPrioritized,
                    env,
                    simulationOnly,
                    overFundPercent,
                    retries,
                    chainId
                  );
                  setLoading(false);
                  toast("Launch Scheduled", {
                    type: "success",
                  });
                } catch (e) {
                  setLoading(false);
                  toast(e.message, {
                    type: "error",
                  });
                }
              }}
              disabled={isLoading}
              style={{ marginBottom: "15px" }}
            >
              Launch
            </Button>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={async () => {
                try {
                  setLoading(true);

                  await stopLaunch();
                  setLoading(false);
                  toast("Launch stopped", {
                    type: "success",
                  });
                } catch (e) {
                  setLoading(false);
                  toast(e.message, {
                    type: "error",
                  });
                }
              }}
              disabled={isLoading}
            >
              Stop Launch
            </Button>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormGroup>
              <div style={{ marginBottom: "15px" }}>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={() => fileInputRef2.current?.click()}
                >
                  Add Wallet Amounts with CSV
                </Button>
                <input
                  type="file"
                  accept=".csv"
                  ref={fileInputRef2}
                  style={{ display: "none" }}
                  onChange={async (e) => {
                    try {
                      handleWalletAmountsFileUpload(e);
                    } catch (e) {
                      toast(e.message, {
                        type: "error",
                      });
                      setLoading(false);
                    }
                  }}
                />
              </div>
              {Object.entries(groups)?.map(([groupName, groupWallets]) => (
                <Accordion key={groupName}>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    {groupName}
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isAll(groupWallets, checked)}
                          onChange={() =>
                            selectAll(groupWallets, checked, setChecked)
                          }
                        />
                      }
                      label={"Select All"}
                      style={{ marginBottom: "5px" }}
                    />
                    {groupWallets?.map((sW) => (
                      <div key={sW?.walletIndex}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              id={sW?.walletIndex}
                              checked={checked[sW?.walletIndex]}
                              onChange={handleChange}
                            />
                          }
                          label={
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "default",
                              }}
                              onClick={(e) => e.preventDefault()}
                            >
                              {sW?.address}
                              <CopyButton text={sW?.address} />
                            </div>
                          }
                        />
                        {!(!checked[sW?.walletIndex] || isGlobal) && (
                          <TextField
                            value={tokenAmounts[sW?.walletIndex]}
                            variant="outlined"
                            label="Number of tokens to purchase"
                            onChange={(e) =>
                              setTokenAmounts((prev) => {
                                prev[sW?.walletIndex] = parseFloat(
                                  e.target.value
                                );
                                return [...prev];
                              })
                            }
                            fullWidth
                            disabled={!checked[sW?.walletIndex] || isGlobal}
                          />
                        )}
                        <FormHelperText>{`Balance: ${
                          tokenBalances ? tokenBalances[sW?.walletIndex] : "-"
                        } ETH`}</FormHelperText>
                      </div>
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))}
            </FormGroup>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default Launch;
